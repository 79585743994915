import moment from 'moment';
import { DATE_FORMAT_DDMMYYYY, DATE_FORMAT_MMYY } from 'constants/index';
import formatPrice from '../formatPrice';
import { HYPERWALLET_ALLOWED_COUNTRIES } from 'modules/payments/pages/Withdraw/constants';
import { get, isBoolean } from 'lodash';
import formatBytes from '../formatBytes';
import { formatFileTypes2 } from '../formatFileTypes';

export const email = (value) =>
  value &&
  (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    value,
  ) ||
    value.includes('@-'))
    ? { key: 'common:errors.form.email' }
    : undefined;

export const required = (value) =>
  value === 0 || (value && /\w+/.test(value))
    ? undefined
    : { key: 'common:errors.form.isRequired' };

export const requiredBool = (value) =>
  isBoolean(value) ? undefined : { key: 'common:errors.form.isRequired' };

export const requiredArray = (value) =>
  Array.isArray(value) && value.length > 0 ? undefined : { key: 'common:errors.form.isRequired' };

/* eslint-disable no-control-regex */
export const checkNonLatin = (value) =>
  /^[\u0000-\u007F]*$/.test(value) ? undefined : { key: 'common:errors.form.nonLatin' };
export const checkNonLatinWithNumbers = (value) =>
  /^[\u0000-\u007F0-9]*$/.test(value) ? undefined : { key: 'common:errors.form.nonLatin' };

/* eslint-enable no-control-regex */

export const captcha = (value) =>
  value === 0 || (value && /\w+/.test(value)) ? undefined : { key: 'common:errors.form.captcha' };

export const dateIsValid = (value) =>
  !value || moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isValid(moment())
    ? undefined
    : { key: 'common:errors.form.invalidDate' };

export const dateLessThenToday = (value) =>
  !value || moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrBefore(moment())
    ? undefined
    : { key: 'common:errors.form.dateLessThenToday' };

export const dateAfter1900 = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrAfter(
    moment('01-01-1900', 'DD-MM-YYYY'),
  )
    ? undefined
    : { key: 'common:errors.form.dateAfter1900' };

export const fullAgeDateOfBirth = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrBefore(moment().add(-18, 'years'))
    ? undefined
    : { key: 'common:errors.form.fullAgeDateOfBirth' };

export const dateMoreThanToday = (value) =>
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrAfter(moment().startOf('day'))
    ? undefined
    : { key: 'common:errors.form.dateMoreThanToday' };

export const expiryCardDate = (value) =>
  moment(value, [moment.ISO_8601, DATE_FORMAT_MMYY]).isSameOrAfter(moment().startOf('day'))
    ? undefined
    : { key: 'common:errors.form.expiryCardDate' };

export const dateRange = (first, second) =>
  moment(first).isBefore(moment(second)) ? undefined : { key: 'common:errors.form.dateRange' };

export const dateIsSane = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isBetween(
    moment().add(-200, 'years'),
    moment().add(200, 'years'),
  )
    ? undefined
    : { key: 'common:errors.form.dateIsSane' };

export const trackingNumber = (value) =>
  value && !/^([0-9]{10})$/i.test(value) ? { key: 'common:errors.form.trackingNumber' } : undefined;

let phoneValidatorCreator = (fieldName) => {
  return (value) =>
    value && !/^\+[0-9]{10,15}$/i.test(value)
      ? { key: 'errors.form.phone', values: [{ fieldName: '', key: fieldName }] }
      : undefined;
};

export const phoneNumber = phoneValidatorCreator('common:errors.descriptiors.phone');

export const faxNumber = phoneValidatorCreator('common:errors.descriptiors.fax');

export const cellNumber = phoneValidatorCreator('common:errors.descriptiors.cell');

export const contactNumber = phoneValidatorCreator('common:errors.descriptiors.contact');

export const einNumber = (value) =>
  value && !/^([0-9]{2}-[0-9]{7})$/i.test(value)
    ? { key: 'common:errors.form.einNumber' }
    : undefined;

export const npiNumber = (value) =>
  value && !/^([0-9]{10})$/i.test(value) ? { key: 'common:errors.form.npiNumber' } : undefined;

export const pinCode = (value) =>
  value && !/^([0-9]{4})$/i.test(value) ? { key: 'common:errors.form.pinCode' } : undefined;

export const vinNumber = (value) =>
  value && !/^([0-9A-HJ-NPR-Za-hj-npr-z]+)$/i.test(value)
    ? { key: 'common:errors.form.vinNumber' }
    : undefined;

export const positiveNumber = (value) => {
  if (value) {
    if (String(value).startsWith('.')) {
      return { key: 'common:errors.form.invalidDataType' };
    }
    if (!/^[+]?[0-9]+(\.[0-9]+)?$/.test(value)) {
      return { key: 'common:errors.form.positiveNumber' };
    }
    if (!/^[0-9.]{1,17}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    if (!/[.]/.test(value) && !/^[0-9]{1,16}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    return undefined;
  }
};

export const moreThanZero = (value) =>
  value && Number(value) <= 0 ? { key: 'common:errors.form.moreThanZero' } : undefined;

export const moreOrZero = (value) =>
  value && Number(value) < 0 ? { key: 'common:errors.form.moreOrZero' } : undefined;

export const positiveInteger = (value) => {
  if (value) {
    if (!/^([0-9]+)$/i.test(value)) {
      return { key: 'common:errors.form.positiveInteger' };
    }
    if (!/^[0-9]{1,16}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    return undefined;
  }
};

export const cardNumber = (value) =>
  value && !/^([0-9]{16})$/i.test(value) ? { key: 'common:errors.form.cardNumber' } : undefined;

export const notZero = (value) =>
  value && Number(value) === 0 ? { key: 'common:errors.form.notZero' } : undefined;

export const yearInteger = (value) =>
  value && (!(1 * value < 2200 && 1 * value > 1800) || !/^([0-9]+)$/i.test(value))
    ? { key: 'common:errors.form.yearInteger' }
    : undefined;

export const yearLessOrEqualCurrent = (value) =>
  value && !(1 * value < 1 + moment().year())
    ? { key: 'common:errors.form.yearLessOrEqualCurrent' }
    : undefined;

export const positiveInteger24 = (value) =>
  value && !/^([01]?[0-9]|2[0-4])$/i.test(value)
    ? { key: 'common:errors.form.positiveInteger24' }
    : undefined;

export const positiveInteger31 = (value) =>
  value && (Number(value) > 31 || Number(value) < 0)
    ? { key: 'common:errors.form.positiveInteger31' }
    : undefined;

let positiveIntegerCreator = (fieldName, amount) => {
  return (value) =>
    value && (Number(value) < amount || Number(value) < 0)
      ? {
          key: 'common:errors.form.positiveIntegerCreator',
          values: [{ fieldName: '', key: fieldName }, { amount }],
        }
      : undefined;
};

export const positiveInteger10Withdrawal = positiveIntegerCreator(
  'common:errors.descriptiors.withdrawal',
  10,
);

export const positiveInteger35Withdrawal = positiveIntegerCreator(
  'common:errors.descriptiors.withdrawal',
  35,
);

export const positiveInteger10Deposit = positiveIntegerCreator(
  'common:errors.descriptiors.deposit',
  10,
);

export const positiveIntegerMinWithCurrency = (value, fieldName, amount, currency) => {
  return value && (Number(value) < amount || Number(value) < 0)
    ? {
        key: 'common:errors.form.positiveIntegerMinWithCurrency',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(amount, currency) }],
      }
    : undefined;
};

export const positiveIntegerMaxWithCurrency = (value, fieldName, amount, currency) => {
  return value && Number(value) > amount
    ? {
        key: 'common:errors.form.positiveIntegerMaxWithCurrency',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(amount, currency) }],
      }
    : undefined;
};

export const positiveIntegerMinWithdrawal = (value, amount, currency) => {
  return positiveIntegerMinWithCurrency(
    value,
    'common:errors.descriptiors.withdrawal',
    amount,
    currency,
  );
};

export const positiveIntegerMinDeposit = (value, amount, currency) => {
  return positiveIntegerMinWithCurrency(
    value,
    'common:errors.descriptiors.deposit',
    amount,
    currency,
  );
};

export const positiveIntegerMaxDeposit = (value, amount, currency) => {
  return positiveIntegerMaxWithCurrency(
    value,
    'common:errors.descriptiors.deposit',
    amount,
    currency,
  );
};

export const positiveIntegerMax = (value, fieldName, balance, currency) => {
  return value && Number(value) > Number(balance)
    ? {
        key: 'common:errors.form.positiveIntegerMax',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(balance, currency) }],
      }
    : undefined;
};

export const positiveIntegerMaxWithdrawal = (value, balance, currency) => {
  if (Number(balance) === 0) {
    return { key: 'common:errors.form.positiveIntegerMaxWithdrawal' };
  }
  return positiveIntegerMax(value, 'common:errors.descriptiors.withdrawal', balance, currency);
};

export const number = (value) =>
  value && isNaN(Number(value))
    ? {
        key: 'common:errors.form.invalidDataType',
      }
    : undefined;

export const maxNumberLength = (value) => {
  if (value) {
    if (value > 999999.99) {
      return {
        key: 'common:errors.form.maxNumberLength',
      };
    }
    if (value < -999999.99) {
      return {
        key: 'common:errors.form.minNumberLength',
      };
    }
    return undefined;
  }
};

export const maxNumLength16 = (value) => {
  if (value.length > 16) {
    return {
      key: 'common:errors.form.maxCharacterLength',
    };
  }

  return undefined;
};

export const maxNumLength35 = (value) => {
  if (value.length > 16) {
    return {
      key: 'common:errors.form.maxCharacterLength',
    };
  }

  return undefined;
};

export const maxNumberOfDecimalPlaces = (value) =>
  !/^\d+(?:\.\d{1,3})?$/.test(value)
    ? {
        key: 'common:errors.form.maxNumberOfDecimalPlaces',
      }
    : undefined;

export const customMaxNumberOfDecimalPlaces = (max) => (value) =>
  !new RegExp(`^\\d+(?:\\.\\d{1,${max}})?$`).test(value)
    ? {
        key: 'common:errors.form.customMaxNumberOfDecimalPlaces',
        values: [{ count: max }],
      }
    : undefined;

export const positiveInteger168 = (value) =>
  value && (!/^[+-]?[0-9]+(\.[0-9]+)?$/i.test(value) || Number(value) > 168 || Number(value) < 0)
    ? {
        key: 'common:errors.form.positiveInteger168',
      }
    : undefined;

export const expirationCreditCartDate = (value) => {
  if (!/^([0-9]{4})$/i.test(value)) {
    return {
      key: 'common:errors.form.InvalidExpirationDate',
    };
  }
  const creditCardDate = moment(value, 'MMYY');
  let isValid = creditCardDate.isValid() && moment() < creditCardDate.add(1, 'months');
  return !isValid
    ? {
        key: 'common:errors.form.InvalidExpirationDate',
      }
    : undefined;
};

export const ABARoutingNumber = (value) =>
  value && !/^([0-9]{9})$/i.test(value)
    ? {
        key: 'common:errors.form.ABARoutingNumber',
      }
    : undefined;

export const accountNumber = (value) =>
  value && !/^([0-9]{10,12})$/i.test(value)
    ? {
        key: 'common:errors.form.ABARoutingNumber',
      }
    : undefined;

export const stringLength255 = (value) =>
  value && value.length > 255
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 255 }],
      }
    : undefined;

export const stringLength100 = (value) =>
  value && value.length > 100
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 100 }],
      }
    : undefined;

export const stringLengthMin2 = (value) =>
  value && value.length < 2
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 2 }],
      }
    : undefined;

export const stringLengthMin3 = (value) =>
  value && value.length < 3
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 3 }],
      }
    : undefined;

export const stringLengthMin4 = (value) =>
  value && value.length < 4
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 4 }],
      }
    : undefined;

export const stringLengthMin5 = (value) =>
  value && value.length < 5
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 5 }],
      }
    : undefined;

export const stringLengthMin8 = (value) =>
  value && value.length < 8
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 8 }],
      }
    : undefined;

export const stringLengthMin6 = (value) =>
  value && value.length < 6
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 6 }],
      }
    : undefined;

export const stringLengthMax4 = (value) => {
  return value && value.length > 4
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 4 }],
      }
    : undefined;
};

export const stringLengthMax6 = (value) => {
  return value && value.length > 6
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 6 }],
      }
    : undefined;
};

export const stringLengthMax10 = (value) => {
  return value && value.length > 10
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 10 }],
      }
    : undefined;
};

export const stringLengthMax128 = (value) =>
  value && value.length > 128
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 128 }],
      }
    : undefined;

export const stringLengthMax256 = (value) =>
  value && value.length > 256
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 256 }],
      }
    : undefined;

export const stringLengthMax64 = (value) =>
  value && value.length > 64
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 64 }],
      }
    : undefined;

export const stringLengthMax34 = (value) =>
  value && value.length > 34
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 34 }],
      }
    : undefined;

export const stringLength512 = (value) =>
  value && value.length > 512
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 512 }],
      }
    : undefined;

export const stringLength500 = (value) =>
  value && value.length > 500
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 500 }],
      }
    : undefined;

export const stringLength120 = (value) =>
  value && value.length > 120
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 120 }],
      }
    : undefined;

export const stringLength200 = (value) =>
  value && value.length > 200
    ? {
        key: 'The maximum length of the field must be 200 characters',
      }
    : undefined;

export const stringLength60 = (value) =>
  value && value.length > 60
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 60 }],
      }
    : undefined;

export const stringLength50 = (value) =>
  value && value.length > 50
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 50 }],
      }
    : undefined;

export const stringLengthEquals20 = (value) => {
  return value && value.length !== 20
    ? {
        key: 'LEI must be a 20 character string',
      }
    : undefined;
};

export const password = (value) => {
  let count = 0;
  let regex = {
    lowerCase: /^(?=.*[a-z]).+$/g,
    upperCase: /^(?=.*[A-Z]).+$/g,
    number: /^(?=.*[0-9]).+$/g,
    threeInARow: /(\w|\W)\1\1\1*/g,
    specialCharacters: /[!@#$%^&*]/g,
  };

  if (value && value.length < 10) {
    return {
      key: 'common:errors.form.passwordNumberCharacters',
      values: [{ count: 10 }],
    };
  }

  if (value && !regex.lowerCase.test(value)) {
    count += 1;
  }
  if (value && !regex.upperCase.test(value)) {
    count += 1;
  }
  if (value && !regex.number.test(value)) {
    count += 1;
  }
  if (value && !regex.specialCharacters.test(value)) {
    count += 1;
  }

  if (count > 1) {
    return {
      key: 'common:errors.form.password3of4Types',
    };
  }

  if (value && regex.threeInARow.test(value)) {
    return {
      key: 'common:errors.form.passwordIdenticalCharactersInARow',
      values: [{ count: 2 }],
    };
  }
  return undefined;
};

export const userState = (value) => {
  const regex = /^[a-zA-Z&\-()\-' ]*$/g;
  if (value && !regex.test(value)) {
    return {
      key: 'common:errors.form.invalidUserState',
    };
  }
  return undefined;
};

export const companyCity = (value) =>
  value && value.length > 40
    ? {
        key: 'common:errors.form.companyCity',
      }
    : undefined;

export const companyZip = (value) =>
  value && !/^([0-9]{5})$/i.test(value)
    ? {
        key: 'common:errors.form.companyZip',
      }
    : undefined;

export const isFloat = (value) =>
  !/^[+-]?[0-9]+(\.[0-9]+)?$/i.test(value)
    ? {
        key: 'common:errors.form.isFloat',
      }
    : undefined;

export const prnEvery = (value) =>
  (value && (value < 1 || value > 24)) ||
  !Number.isInteger(value - 0) ||
  value.toString().includes('.')
    ? {
        key: 'common:errors.form.positiveInteger24',
      }
    : undefined;

export const checkHyperwalletField = (value, allValues, props, validators) => {
  const field =
    props.bankwireFields && props.bankwireFields.find((field) => field.name === validators);
  const check = field && field.regularExpression ? new RegExp(field.regularExpression) : '';

  const userCountry = get(props, 'userCountry', '');
  const targetCurrency = get(props, 'targetCurrency.currency', '');

  if (value && !check.test(value)) {
    if (userCountry && HYPERWALLET_ALLOWED_COUNTRIES.has(userCountry) && targetCurrency) {
      return {
        key: `hyperwallet:validators.${userCountry}.${targetCurrency}.${validators}`,
      };
    }

    return {
      key: 'common:errors.form.hyperwalletFieldMinMaxLength',
      values: [{ min: field.minLength, max: field.maxLength }],
    };
  }

  return undefined;
};

export const isAddressNotContainPoBox = (value) => {
  return /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(value)
    ? {
        key: 'common:errors.form.addressContainPoBox',
      }
    : undefined;
};

export const checkIfContainsUnderscore = (value) => {
  return value.toString().includes('_')
    ? {
        key: 'common:errors.form.underscoreNotAllowed',
      }
    : undefined;
};

export const isPayIdOrganisationId = (value) => {
  return !/^[!-@[-~][ -@[-~]{0,254}[!-@[-~]$/g.test(value)
    ? {
        key: 'common:errors.form.payIdOrganisationId',
      }
    : undefined;
};

export const validatePayIdPhoneNumber = (value) => {
  return !/^[+][0-9]{1,3}-[1-9]{1,1}[0-9]{1,29}$/g.test(value)
    ? {
        key: 'common:errors.form.payIdPhoneNumber',
      }
    : undefined;
};

export const stringLengthIs9 = (value) =>
  value && value.length !== 9
    ? {
        key: 'common:errors.form.stringExactNumber',
        values: [{ count: 9 }],
      }
    : undefined;

export const stringLengthIs11 = (value) =>
  value && value.length !== 11
    ? {
        key: 'common:errors.form.stringExactNumber',
        values: [{ count: 11 }],
      }
    : undefined;

const ONE_DAY_IN_MS = 1000 * 3600 * 24;

export const datesDiffMoreThanOneDay = (date, formData) => {
  const startDate = new Date(date);
  const endDate = formData.endDate ? new Date(formData.endDate) : new Date();

  if (formData.continueWorking || !formData.endDate) {
    return undefined;
  }

  return endDate.getTime() - startDate.getTime() > ONE_DAY_IN_MS
    ? undefined
    : { key: 'common:errors.form.dateRange' };
};

export const maxFiles = (max) => (values) => {
  return values && values.length > max
    ? { key: 'common:errors.form.maxNumberOfFiles', values: [{ maxFiles: max }] }
    : undefined;
};

//check dropzone error codes
export const allowedFilesTypes = (types) => (values) => {
  return values &&
    values.length &&
    values.some((val) => val.errors && val.errors[0].code === 'file-invalid-type')
    ? {
        key: 'common:errors.form.acceptedFileTypes',
        values: [{ acceptTypes: formatFileTypes2(types) }],
      }
    : undefined;
};

export const maxFilesSize = (maxSize) => (values) => {
  return values &&
    values.length &&
    values.some((val) => val.errors && val.errors[0].code === 'file-too-large')
    ? {
        key: 'common:errors.form.allowedMaxFileSize',
        values: [{ maxSize: formatBytes(maxSize) }],
      }
    : undefined;
};

export const noClientTypesChecked = (values) => {
  const result = values.every((value) => value.checked === false);
  return result ? 'Please select at least one client type.' : undefined;
};

export const duplicatedPresetName = (existingPresets) => (value) => {
  if (value && value.length > 0 && existingPresets) {
    const isNameExists = existingPresets.includes(value);
    if (isNameExists) {
      return `Preset name ${value} already exists.`;
    }
  }
  return undefined;
};

export const maxFiltersCount = (max, filterCount) => () => {
  return filterCount >= max ? `Sorry, you have max count of preset filters (${max})` : undefined;
};

export const nonEmptyString = (value, values, scope, field) => {
  return value === '' && values[field] === ''
    ? { key: 'common:errors.form.isRequired' }
    : undefined;
};
