export default {
  infoIcon: (theme) => ({
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    marginTop: '-3px',
  }),
  minimizedInfo: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
};
