import PropTypes from 'prop-types';
import MaterialDivider from 'material-latest/Divider';

import { styles } from './styles';
const Divider = ({
  orientation = 'horizontal',
  variant = 'middle',
  spacing = 0,
  flexItem = false,
}) => {
  return (
    <MaterialDivider
      sx={styles}
      variant={variant}
      orientation={orientation}
      flexItem={flexItem}
      style={{ margin: variant === 'horizontal' ? `0 ${spacing}px` : `${spacing}px 0` }}
    />
  );
};

Divider.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  variant: PropTypes.oneOf(['fullWidth', 'middle']),
  spacing: PropTypes.number,
  flexItem: PropTypes.bool,
};
export default Divider;
