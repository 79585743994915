import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import Box from 'material-latest/Box';

import TPPagination from 'components/TP-UI/TPPagination';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLoader from 'components/TP-UI/TPLoader';
import { MAIN_COLORS } from 'components/TP-UI/constants';
import { getDeepObjectValue, hasOnlyEmptyValues } from '../helpers/object';
import { DEFAULT_PAGINATION, TABLE_CHANGE_EVENT_TYPE } from './constants';
import TPColumnsSettings from './components/TPColumnsSettings';
import TPSorting from './components/TPSorting';
import TPFilters from './components/TPFilters';
import TPFileActionsMenu from './components/TPFileActionsMenu';
import { getVisibleColumns } from './helpers/columns';

import styles from './styles';

const empty = {};

const TPTable = ({
  items,
  itemKey = 'id',
  columns = [],
  sortBy,
  sortAsc,
  filters,
  fileActions,
  columnsSettings,
  allowColumnsSettings = false,
  pagination = DEFAULT_PAGINATION,
  loading = false,
  disabled = false,
  noDataText = 'No data',
  noMatchesText = 'No matches found',
  scrollable = true,
  hidePagination = false,
  summaryComponent: SummaryComponent,
  onChange,
  className,
  getRowClassName,
}) => {
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [sorting, setSorting] = useState();
  const [sortingOptions, setSortingOptions] = useState([]);
  const { currentPage, pageSize, ...paginationProps } = pagination;
  const [filtered, setFiltered] = useState(false);
  const filterFormValues = useSelector((state) => {
    if (filters?.form) {
      return getFormValues(filters.form)(state);
    } else {
      return empty;
    }
  });
  const provideColumnsInfo = !!columnsSettings && allowColumnsSettings;

  useEffect(() => {
    if (columns) {
      setDisplayedColumns(
        columns.map((c) => ({ ...c, visible: allowColumnsSettings ? !!c.default : true })),
      );
      setSortingOptions(columns.filter((c) => c.sortable));
    }
  }, [allowColumnsSettings, columns]);

  useEffect(() => {
    if (columnsSettings && allowColumnsSettings) {
      setDisplayedColumns(
        columns.map((c) => ({ ...c, visible: columnsSettings.includes(c.dataKey) })),
      );
    }
  }, [columnsSettings, columns, allowColumnsSettings]);

  useEffect(() => {
    if (sortBy) {
      const option = sortingOptions.find((o) => o.dataKey === sortBy);
      if (option) {
        setSorting({ label: option.label, dataKey: option.dataKey, sortDesc: sortAsc ? -1 : 1 });
      }
    }
  }, [sortBy, sortAsc, sortingOptions]);

  useEffect(() => {
    if (filters && filters.initialValues && hasOnlyEmptyValues(filters.initialValues)) {
      setFiltered(true);
    }
  }, [filters]);

  const handlePageChange = useCallback(
    (page, pageSize) => {
      if (onChange) {
        onChange(
          {
            page,
            pageSize,
            sortBy,
            sortAsc,
            ...(provideColumnsInfo ? { columns: getVisibleColumns(displayedColumns) } : {}),
            ...filterFormValues,
          },
          { type: TABLE_CHANGE_EVENT_TYPE.PAGINATION },
        );
      }
    },
    [onChange, sortBy, sortAsc, filterFormValues, provideColumnsInfo, displayedColumns],
  );

  const handleColumnsChange = useCallback(
    (columns) => {
      setDisplayedColumns(columns);
      if (onChange && provideColumnsInfo) {
        onChange(
          {
            page: currentPage,
            pageSize,
            sortBy,
            sortAsc,
            columns: getVisibleColumns(columns),
            ...filterFormValues,
          },
          { type: TABLE_CHANGE_EVENT_TYPE.COLUMNS },
        );
      }
    },
    [onChange, currentPage, pageSize, sortBy, sortAsc, filterFormValues, provideColumnsInfo],
  );

  const handleSortingChange = useCallback(
    (value) => {
      setSorting(value);
      if (onChange) {
        onChange(
          {
            //reset selected page since sorting is changed
            page: 0,
            pageSize,
            sortBy: value?.dataKey,
            sortAsc: value?.sortDesc !== 1,
            ...(provideColumnsInfo ? { columns: getVisibleColumns(displayedColumns) } : {}),
            ...filterFormValues,
          },
          { type: TABLE_CHANGE_EVENT_TYPE.SORTING },
        );
      }
    },
    [filterFormValues, onChange, pageSize, provideColumnsInfo, displayedColumns],
  );

  const handleFiltersChange = useCallback(
    (values) => {
      if (values) {
        setFiltered(!hasOnlyEmptyValues(values));
      }
      if (onChange) {
        onChange(
          {
            ...values,
            //reset selected page since filters are changed
            page: 0,
            pageSize,
            sortBy,
            sortAsc,
            ...(provideColumnsInfo ? { columns: getVisibleColumns(displayedColumns) } : {}),
          },
          { type: TABLE_CHANGE_EVENT_TYPE.FILTERS },
        );
      }
    },
    [onChange, pageSize, sortBy, sortAsc, displayedColumns, provideColumnsInfo],
  );

  const showNoData = (!loading && !items) || items?.length === 0;
  const noData = showNoData && !filtered ? noDataText : noMatchesText;
  const showData = items && items.length > 0;
  const showHeader =
    filters || allowColumnsSettings || sortingOptions.length > 0 || fileActions?.length > 0;
  const pageItems = hidePagination ? items : items?.slice(0, pageSize);
  const { form } = filters ?? {};
  const columnsFormConfig = form ? { form: `${filters.form}_COLUMNS` } : {};

  return (
    <Box sx={[styles.container, className]}>
      {showHeader ? (
        <TPFilters
          {...(filters || {})}
          loading={loading}
          disabled={disabled}
          onSubmit={handleFiltersChange}>
          {fileActions ? (
            <TPFileActionsMenu loading={loading} disabled={disabled} actions={fileActions} />
          ) : null}
          {allowColumnsSettings ? (
            <TPColumnsSettings
              {...columnsFormConfig}
              title="Columns Visibility Settings"
              columns={displayedColumns}
              loading={loading}
              disabled={disabled}
              onChange={handleColumnsChange}
            />
          ) : null}
          {sortingOptions.length > 0 ? (
            <TPSorting
              value={sorting}
              options={sortingOptions}
              loading={loading}
              disabled={disabled}
              onChange={handleSortingChange}
            />
          ) : null}
        </TPFilters>
      ) : null}
      <Box sx={[styles.contentContainer, loading && styles.contentLoading]}>
        <Box sx={[styles.content, scrollable && styles.scrollable]} role="table">
          {showData ? (
            <Box sx={styles.root} role="rowgroup">
              {pageItems.map((item) => {
                const rowClassName = getRowClassName ? getRowClassName(item) : {};
                return (
                  <Box role="row" sx={[styles.row, rowClassName]} key={item[itemKey]}>
                    {displayedColumns.map((column, index) => {
                      let value = column.dataKey ? getDeepObjectValue(item, column.dataKey) : null;
                      if (value !== undefined && column.format) {
                        value = column.format(value);
                      }
                      if (Array.isArray(value)) {
                        value = value.join(', ');
                      }
                      if ((value === null || value === undefined) && column.defaultValue) {
                        value = column.defaultValue;
                      }
                      return column.visible ? (
                        <Box
                          sx={[styles.cell, column.className]}
                          key={`${column.dataKey || ''}_${index}`}
                          style={{
                            minWidth: column.width || 'auto',
                            textAlign: column.textAlign || 'left',
                          }}>
                          {column.label ? (
                            <TPTypography
                              role="columnheader"
                              className={styles.columnTitle}
                              variant="caption"
                              color={MAIN_COLORS.SECONDARY}>
                              {column.label}
                            </TPTypography>
                          ) : null}
                          <Box
                            role="cell"
                            sx={[column.textStyle === 'bold' && styles.cellBoldText]}>
                            {column.renderItem ? (
                              column.renderItem(item)
                            ) : column.dataKey ? (
                              <TPTypography variant="body2">{value ?? ''}</TPTypography>
                            ) : null}
                          </Box>
                        </Box>
                      ) : null;
                    })}
                  </Box>
                );
              })}
            </Box>
          ) : null}
          {showNoData ? (
            <Box sx={styles.tableNoDataContainer}>
              <TPTypography
                variant="h4"
                component={typeof noData === 'string' ? 'p' : 'div'}
                color={MAIN_COLORS.SECONDARY}>
                {noData}
              </TPTypography>
            </Box>
          ) : null}
        </Box>
        {SummaryComponent ? <Box sx={styles.summary}>{<SummaryComponent />}</Box> : null}
        {loading ? (
          <Box sx={styles.tableLoader}>
            <TPLoader />
          </Box>
        ) : null}
      </Box>
      {!hidePagination ? (
        <TPPagination
          value={currentPage}
          pageSize={pageSize}
          {...paginationProps}
          className={styles.pagination}
          disabled={loading || disabled}
          onChange={handlePageChange}
        />
      ) : null}
    </Box>
  );
};

TPTable.propTypes = {
  /**
   * Data items
   */
  items: PropTypes.array,
  /**
   * Name(key) of unique item property, ex. id
   */
  itemKey: PropTypes.string,
  filters: PropTypes.shape(TPFilters.propTypes),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column title
       */
      label: PropTypes.node,
      /**
       * Name(key) of item property, ex. email
       */
      dataKey: PropTypes.string,
      width: PropTypes.string,
      defaultValue: PropTypes.string,
      /**
       * Displayed by default, set it when using allowColumnsSettings
       */
      default: PropTypes.bool,
      /**
       * Set to true when column should be sortable
       */
      sortable: PropTypes.bool,
      textAlign: PropTypes.oneOf(['left', 'center', 'right']),
      textStyle: PropTypes.oneOf(['', 'bold']),
      className: PropTypes.string,
      /**
       * Called when item(cell) is rendered, function (item) => 'formatted string'
       */
      format: PropTypes.func,
      /**
       * Called when item(cell) is rendered, function (item) => <Custom component />
       */
      renderItem: PropTypes.func,
    }),
  ),
  /**
   * Data key name, ex. email
   */
  sortBy: PropTypes.string,
  sortAsc: PropTypes.bool,
  /**
   * List of actions, ex generate xslt, download CSV and etc.
   */
  fileActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
  /**
   * List of column's dataKey values, that should be visible for any reason
   */
  columnsSettings: PropTypes.arrayOf(PropTypes.string),
  allowColumnsSettings: PropTypes.bool,
  pagination: PropTypes.shape({
    /**
     * Current page value
     */
    currentPage: PropTypes.number,
    /**
     * Number of data items per page
     */
    pageSize: PropTypes.number,
    /**
     * List of possible page size values
     */
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    /**
     * Total number of data items
     */
    total: PropTypes.number,
    /**
     * true means that pageNumber will be get/set from/in the browser url as parameter :pageNumber
     */
    nav: PropTypes.bool,
  }),
  summaryComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  scrollable: PropTypes.bool,
  hidePagination: PropTypes.bool,
  noDataText: PropTypes.node,
  noMatchesText: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]),
  getRowClassName: PropTypes.func,
  /**
   * Called when the page number or pageSize is changed, or any filter
   * or sorting or columns (if allowed change columns and columnSettings are defined),
   * type: 'pagination' || 'sorting' || 'filters' || 'columns', TABLE_CHANGE_EVENT_TYPE
   * function ({page, pageSize, sortBy, sortAsc, columns, ...filters}, { type })
   */
  onChange: PropTypes.func,
};

export default TPTable;
