import { combineReducers } from 'redux';

import clients from '../pages/Clients/reducers';
import myClients from '../pages/MyClients/reducers';
import userDetails from '../pages/UserDetails/reducers';
import employees from '../pages/Employees/reducers';
import blockedUsers from '../pages/BlockedUsers/reducers';
import birthdays from '../pages/ClientsBirthdays/reducers';
import followUps from '../pages/FollowUps/reducers';
import notifications from '../pages/Notifications/reducers';
import notificationsCount from './notificationsCount';
import vpsUsers from '../pages/VPSUsers/reducers';
import deletedUsers from '../pages/DeletedClients/reducers';
import fusionPlusUsers from '../pages/FusionPlusUsers/reducers';
import presets from './presets';

const reducer = combineReducers({
  clients,
  myClients,
  userDetails,
  employees,
  blockedUsers,
  birthdays,
  followUps,
  notifications,
  notificationsCount,
  vpsUsers,
  deletedUsers,
  presets,
  fusionPlusUsers,
});

export default reducer;
