import { fontWeight } from '../../CustomThemeProviderMui5/themes/font';

export default {
  container: {
    overflow: 'hidden',
  },
  contentContainer: (theme) => ({
    clear: 'both',
    overflow: 'hidden',
    position: 'relative',
    border: `1px solid ${theme.palette.primary.lightest}`,
  }),
  content: {
    minHeight: '50px',
    overflow: 'auto',
  },
  contentLoading: {
    opacity: 0.7,
  },
  scrollable: {
    overflow: 'auto',
    maxHeight: 'calc(65vh - 17px)',
    width: '100%',
    '@media (max-width:420px)': {
      maxHeight: '380px', // ~5 rows
    },
  },
  tableNoDataContainer: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    display: 'table',
    width: '100%',
  },
  columnTitle: {
    whiteSpace: 'nowrap',
  },
  row: {
    display: 'table-row',
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: (theme) => ({
    display: 'table-cell',
    verticalAlign: 'top',
    padding: '16px',
    minWidth: '50px',
    borderBottom: `1px solid ${theme.palette.primary.lightest}`,
  }),
  cellBoldText: {
    fontWeight: fontWeight.bold,
    '& *': {
      fontWeight: fontWeight.bold,
    },
  },
  cellFixed: {
    position: 'sticky',
    right: 0,
    background: 'white',
  },
  tableLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  pagination: {
    marginTop: '16px',
  },
  summary: (theme) => ({
    background: theme.palette.primary.lightest,
    padding: '16px',
  }),
};
