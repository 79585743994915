const styles = (theme) => ({
  root: {
    maxWidth: '1290px',
    paddingBottom: '68px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  accountList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  infoBox: {
    marginBottom: '24px',
  },
  loader: {
    margin: '40px',
    textAlign: 'center',
  },
  noDemoRoot: {
    width: '100%',
    padding: '42px 60px 70px 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(768)]: {
      padding: 0,
      paddingTop: 42,
    },
  },
  zeroAccounts: {
    width: '100%',
    maxWidth: 440,
  },
  noDemoMessage: {
    color: theme.palette.black,
    fontWeight: 600,
    marginTop: 10,
    fontSize: 20,
    textAlign: 'center',
  },
  noDemoText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '21px',
    marginTop: 10,
    color: theme.palette.black,
  },
  noDemoLink: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.black,
  },
  notExists: {
    fontWeight: 600,
    fontSize: 20,
  },
  newAccountButton: {
    marginTop: 22,
  },
  infoBoxWrapper: {
    paddingTop: 24,
  },
  quizReminder: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width:768px)': {
      flexDirection: 'row',
      gap: '16px',
    },
  },
  tooltipContainer: {
    display: 'initial',
    minWidth: '155px',
  },
  tooltipLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    flexWrap: 'nowrap',
  },
  tooltipIcon: {
    fontSize: 16,
  },
  tooltipText: {
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 700,
  },
  warningIcon: {
    width: 12,
    height: 12,
    color: theme.palette.moodyBlue,
    verticalAlign: 'middle',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px',
  },
  showHidden: {
    color: theme.palette.simpleGrey,
    fontSize: 18,
    fontWeight: 300,
    padding: 0,
    paddingRight: 10,
    border: 'none',
    textAlign: 'right',
    backgroundColor: 'initial',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },
  },
  followButton: {
    marginLeft: '10px',
  },
  followAccountModal: {
    maxWidth: '742px',
    width: '100%',
    minWidth: '320px',
  },
  joinModal: {
    width: '100%',
    maxWidth: '720px',
    minWidth: '520px',
  },
  emptyDataImg: {
    width: '100%',
    maxWidth: '400px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
});

export default styles;
