export const IDENTIFY_BY = {
  SPECIAL_LINK: 'SPECIAL_LINK',
  OWN_ACCOUNT: 'OWN_ACCOUNT',
  EMAIL: 'EMAIL',
};
export const MODAL_WIDTH_LARGE = '724';

export const TRADES_TABS = {
  OPEN: 'openTrades',
  CLOSED: 'tradingHistory',
  ERROR: 'errorTrades',
};

export const INVITE_TYPE = {
  OWN: 'own',
  LINK: 'link',
  EMAIL: 'email',
};

export const ADD_MY_FOLLOWER_MODAL = 'ADD_MY_FOLLOWER_MODAL';

export const ADD_MY_FOLLOWER_FORM = 'ADD_MY_FOLLOWER_FORM';

export const JOIN_FORM = 'JOIN_FORM';

export const DELETE_CONFIRMATION_MODAL = 'DELETE_CONFIRMATION_MODAL';

export const DELETE_MASTER_ACCOUNT_CONFIRMATION_MODAL = 'DELETE_MASTER_ACCOUNT_CONFIRMATION_MODAL';

export const DELETE_INVITE_CONFIRMATION_MODAL = 'DELETE_INVITE_CONFIRMATION_MODAL';

export const EDIT_CONFIRMATION_MODAL = 'EDIT_CONFIRMATION_MODAL';

export const EDIT_CONFIRMATION_FORM = 'EDIT_CONFIRMATION_FORM';

export const JOIN_PLUS_MODAL = 'JOIN_PLUS_MODAL';

export const JOIN_PLUS_FORM = 'JOIN_PLUS_FORM';

export const FORCE_JOIN_EMAIL_MODAL = 'FORCE_JOIN_EMAIL_MODAL';

export const TURN_OFF_INVITATION_MODAL = 'TURN_OFF_INVITATION_MODAL';

export const EDIT_INVITATION_MODAL = 'EDIT_INVITATION_MODAL';

export const EDIT_INVITATION_FORM = 'EDIT_INVITATION_FORM';

export const REJECT_INVITIATION_MODAL = 'REJECT_INVITIATION_MODAL';

export const USER_COPIER_SETTINGS_MODAL = 'USER_COPIER_SETTINGS_MODAL';

export const USER_COPIER_SETTINGS_FORM = 'USER_COPIER_SETTINGS_FORM';

export const EDIT_INVITE_MODAL = 'EDIT_INVITE_MODAL';

export const REQUEST_FUSION_PLUS_MODAL = 'REQUEST_FUSION_PLUS_MODAL';

export const REQUEST_FUSION_PLUS_FORM = 'REQUEST_FUSION_PLUS_FORM';

export const RELOAD_ACCOUNT_MODAL = 'RELOAD_ACCOUNT_MODAL';

export const RELOAD_ACCOUNT_FORM = 'RELOAD_ACCOUNT_FORM';

export const MY_FOLLOWERS_JOIN_WAITLIST_MODAL = 'MY_FOLLOWERS_JOIN_WAITLIST_MODAL';

export const MY_FOLLOWERS_APPLY_MASTER_TRADER_MODAL = 'MY_FOLLOWERS_APPLY_MASTER_TRADER_MODAL';
export const MY_FOLLOWERS_LEADERBOARD_SUCCESS_MODAL = 'MY_FOLLOWERS_LEADERBOARD_SUCCESS_MODAL';

export const MY_FOLLOWERS_JOIN_WAITLIST_FORM = 'MY_FOLLOWERS_JOIN_WAITLIST_FORM';
export const MY_FOLLOWERS_APPLY_MASTER_TRADER_FORM = 'MY_FOLLOWERS_APPLY_MASTER_TRADER_FORM';

export const EDIT_SOME_FOLLOWERS_MODAL = 'EDIT_SOME_FOLLOWERS_MODAL';
export const EDIT_SOME_FOLLOWERS_FORM = 'EDIT_SOME_FOLLOWERS_FORM';

export const ACCEPTED_FOLLOWERS_FORM = 'ACCEPTED_FOLLOWERS_FORM';

export const YES_NO = [
  { value: true, label: 'common:constants.yes' },
  { value: false, label: 'common:constants.no' },
];

export const DEFAULT_YES_OPTION = YES_NO[0];
export const DEFAULT_NO_OPTION = YES_NO[1];

export const COPIER_MODES = [
  { value: true, label: 'copySettings.copierMode.enabled' },
  { value: false, label: 'copySettings.copierMode.disabled' },
];
export const DEFAULT_COPIER_MODE_OPTION = COPIER_MODES[0];

export const FEE = [
  { value: 5, label: '5%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 25, label: '25%' },
  { value: 30, label: '30%' },
];

export const FORM_DEFAULTS = {
  copierMode: DEFAULT_COPIER_MODE_OPTION.value,
  emailNotifications: DEFAULT_YES_OPTION.value,
  percentageToMirror: 100,
  fixedSize: 1,
  useFee: DEFAULT_NO_OPTION.value,
  feeValue: 10,
  reverseTrade: DEFAULT_NO_OPTION.value,
  copyPendingOrders: DEFAULT_NO_OPTION.value,
  copyStops: DEFAULT_NO_OPTION.value,
  isEnableAllSymbols: true,
};

export const EMPTY_FORM = {
  copierMode: null,
  moneyManagementTypeId: null,
  percentageToMirror: null,
  fixedSize: null,
  reverseTrade: null,
  copyPendingOrders: null,
  copyStops: null,
  copyOpenTradesMethodId: null,
  emailNotifications: null,
  useFee: null,
  feeValue: null,
  instrumentsIds: [],
  isEnableAllSymbols: null,
};
