import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { styles } from './styles';
import { SIZES } from 'components/TP-UI/constants';
import TPButton from 'components/TP-UI/TPButton';
import StarIcon from '@mui/icons-material/Star';
import { IFRAME_VIDEOS_URLS } from 'modules/tradingTools/contants';
import { Link } from 'material-latest';
import Divider from 'modules/common/Divider';

const DownloadAutochartistMT4 = () => {
  return (
    <TPGrid container spacing={2} pb={10}>
      <TPGrid item xs={12} sx={styles.title}>
        Start Using Autochartist MT4/5 Market Scanner Plugin
      </TPGrid>
      <TPGrid item xs={12} pb={1} sx={styles.text}>
        The MetaTrader Market Scanner plug-in for MetaTrader 4/5 scans the markets for trading
        opportunities for the symbols in your watch list across all time periods. <br />
        It provides filters for fine-tuning searches and historical performance analysis for
        optimising trading strategies.
      </TPGrid>
      <TPGrid item xs={12} pb={2} sx={styles.text}>
        <Link
          href="https://autochartist.com/metatrader_en/"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bold' }}
          download={true}>
          Click here
        </Link>{' '}
        for more detailed information and the installation user guide.
      </TPGrid>
      <TPGrid item xs={12} sx={styles.title}>
        Video Tutorials
      </TPGrid>
      <TPGrid item xs={12}>
        Watch these video tutorials for a better understanding of how the Autochartist MT4/5 Scanner
        works.
      </TPGrid>
      <TPGrid item xs={12}>
        <Divider orientation="horizontal" variant="middle" spacing={10} />
      </TPGrid>
      <TPGrid container item xs={12} spacing={2}>
        <TPGrid container item xs={12} md={4}>
          <TPGrid item xs={12} sx={styles.videosTitle} px={1} py={1.5}>
            ABOUT THE MT4/5 SCANNER
          </TPGrid>
          <TPGrid item xs={12} sx={styles.iframeWrapper}>
            <iframe src={IFRAME_VIDEOS_URLS.ABOUT} style={styles.iframeVideos}></iframe>
          </TPGrid>
        </TPGrid>
        <TPGrid container item xs={12} md={4}>
          <TPGrid item xs={12} sx={styles.videosTitle} px={1} py={1.5}>
            INSTALLING THE SCANNER
          </TPGrid>
          <TPGrid item xs={12} sx={styles.iframeWrapper}>
            <iframe src={IFRAME_VIDEOS_URLS.INSTALLING} style={styles.iframeVideos}></iframe>
          </TPGrid>
        </TPGrid>
        <TPGrid container item xs={12} md={4}>
          <TPGrid item xs={12} sx={styles.videosTitle} px={1} py={1.5}>
            HOW TO USE THE MT4/5 SCANNER
          </TPGrid>
          <TPGrid item xs={12} sx={styles.iframeWrapper}>
            <iframe src={IFRAME_VIDEOS_URLS.HOW_TO_USE} style={styles.iframeVideos}></iframe>
          </TPGrid>
        </TPGrid>
      </TPGrid>
      <TPGrid item xs={12}>
        <Link
          href="https://ac-ai-ea.s3.eu-west-1.amazonaws.com/AutochartistMTEASetup.exe"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          download={true}>
          <TPButton
            sx={{ width: { xs: '100%', sm: 'unset' } }}
            primary
            icon={StarIcon}
            size={SIZES.LARGE}>
            Download MT4/5 Scanner
          </TPButton>
        </Link>
      </TPGrid>
    </TPGrid>
  );
};

export default DownloadAutochartistMT4;
