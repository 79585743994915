import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Option = React.forwardRef((props, ref) => {
  const { classes, isSelected } = props;
  return (
    <components.Option
      {...props}
      className={classNames({
        [classes.selectedOption]: isSelected,
      })}
    />
  );
});

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
};

Option.displayName = 'Option';

export default withStyles(styles)(Option);
