import React from 'react';
import { IFRAME_URLS } from '../contants';
import MainLayoutContainer from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';

const ResearchContainer = () => {
  const { t } = useTranslation('tradingTools');

  return (
    <MainLayoutContainer title={t('titles.research')}>
      <DocumentTitle title={t('titles.research')} />
      <iframe src={IFRAME_URLS.RESEARCH} style={styles.iframe}></iframe>
    </MainLayoutContainer>
  );
};

export default ResearchContainer;
