import { fontWeight, fontSize } from 'components/CustomThemeProviderMui5/themes/font';
import { palette } from 'components/CustomThemeProviderMui5/themes/light/palette';

export const styles = {
  // remove some styles if we will not need to revert to the old design
  iframeVideos: {
    border: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  iframeWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    // style from https://webapp.autochartist.com/componentcontainer/metatrader_tab.php?bid=805&locale=en_GB&user=email&demo=0&token=f65bc9f759165943ba0e31adfdbaab56&expire=1774908000
    paddingBottom: '56.27198%',
  },
  videosTitle: {
    fontWeight: fontWeight.semiBold,
    backgroundColor: '#f1f1f8',
  },
  title: {
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize._20,
  },
  qrCode: {
    height: '350px',
    width: '350px',
    border: 'none',
  },
  cardWrapper: {
    position: 'relative',
  },
  card: {
    p: 6,
    display: 'flex',
    maxWidth: '270px',
    fontWeight: fontWeight.bold,
    fontSize: fontSize._18,
    height: '100%',
    border: `1px solid ${palette.primary.main}`,
  },
  iconContainer: {
    position: 'absolute',
    width: 118,
    height: 118,
    background: 'rgba(192, 248, 38, 0.1)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '20px',
    left: '20px',
    zIndex: '1',
  },
  icon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
