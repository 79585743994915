import React from 'react';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';

import MainLayoutContainer from 'modules/common/MainLayout';
import DownloadMobileAutochartistMT4 from '../components/DownloadMobileAutochartistMT4';
import { classStyles } from './styles';
import { withStyles } from '@material-ui/core';

const DownloadsContainer = ({ classes }) => {
  const { t } = useTranslation('tradingTools');

  return (
    <MainLayoutContainer headerClassName={classes.header} title={t('titles.downloadsMobile')}>
      <DocumentTitle title={t('titles.downloadsMobile')} />
      <DownloadMobileAutochartistMT4 />
    </MainLayoutContainer>
  );
};

export default withStyles(classStyles)(DownloadsContainer);
