import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';
import Spinner from '../Spinner';

const PrimaryButton = ({
  classes,
  className,
  label,
  onClick,
  disabled = false,
  content,
  startIcon,
  fullWidth = false,
  isPending,
  customIcon,
  variant = 'contained',
  ...data
}) => {
  return (
    <Button
      disableTouchRipple
      style={data.style}
      focusRipple
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      fullWidth={fullWidth}
      {...data}
      className={classNames({
        [classes.root]: true,
        [className]: !!className,
        [classes.hidden]: isPending,
      })}>
      <div className={classes.buttonContent} {...data}>
        <div
          className={classNames({
            [classes.loader]: true,
            [classes.hidden]: true,
            [classes.visible]: isPending,
          })}>
          <Spinner />
        </div>
        {customIcon && <img alt="" src={customIcon} />}
        <div
          className={classNames({
            [classes.buttonTextContainer]: true,
            [classes.hidden]: isPending,
          })}>
          {startIcon}
          {label}
          {content}
        </div>
      </div>
    </Button>
  );
};

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.object,
  fullWidth: PropTypes.bool,
  isPending: PropTypes.bool,

  /**
   * Different variants of button component.
   * @default 'contained'
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', PropTypes.string]),
};

export default memo(withStyles(styles)(PrimaryButton));
