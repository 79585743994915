export default {
  circleWrapper: {
    position: 'relative',
    lineHeight: 0,
  },
  circleTrack: (theme) => ({
    color: theme.palette.primary.lightest,
  }),
  circleBar: (theme) => ({
    position: 'absolute',
    left: 0,
    color: theme.palette.primary.dark,
  }),
  circleBarSecondary: (theme) => ({
    color: theme.palette.primary.light,
  }),
  circleLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  lineTrack: (theme) => ({
    width: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.lightest,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
    },
  }),
  lineBarSecondary: (theme) => ({
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.light,
    },
  }),
  lineLabel: (theme) => ({
    marginTop: theme.spacing(1),
  }),
};
