import React from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import TPTypography from 'components/TP-UI/TPTypography';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { SIZES, TRIGGER } from 'components/TP-UI/constants';

import styles from './styles';

const InfoTip = ({
  text,
  tooltipContent,
  minWidth,
  minimized = false,
  className,
  iconSize = 'small',
  bold,
}) => {
  return (
    <TPTooltip
      className={className}
      content={tooltipContent}
      trigger={TRIGGER.CLICK}
      disabled={!tooltipContent}
      minWidth={minWidth}>
      <TPTypography
        variant="inherit"
        bold={bold}
        className={minimized ? styles.minimizedInfo : null}>
        <HelpOutlineIcon fontSize={iconSize} sx={minimized ? null : styles.infoIcon} />
        {minimized ? null : text}
      </TPTypography>
    </TPTooltip>
  );
};

InfoTip.propTypes = {
  text: PropTypes.string,
  tooltipContent: PropTypes.node,
  minWidth: PropTypes.string,
  iconSize: PropTypes.oneOf([SIZES.SMALL, SIZES.MEDIUM, SIZES.LARGE]),
};

export default InfoTip;
