import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import config from 'config';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography, withStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import Checkbox from 'modules/common/Checkbox';
import CustomLink from 'modules/common/CustomLink';
import Button from 'modules/common/PrimaryButton';
import TextInput from 'modules/common/TextInput';

import { getTranslation } from 'utils/compositeTranslationHandler';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import Layout from '../../../../components/LayoutNew';
import styles from './styles';
import Spinner from '../../../../../common/Spinner';

const MfaInput = ({ classes, handleSubmit, error = null, isPending, handleLogout }) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, SIGNIN_DISCLAIMER, LOGO } = config;

  return (
    <Layout>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle title={t('mfa.documentTitle', { company: CURRENT_HUB_LABEL })} />
        <div className={classes.formWrapper}>
          <CustomLink
            onClick={handleLogout}
            className={classes.logoWrapper}
            to={getStaticSiteUrl()}>
            <img src={LOGO.blue} className={classes.logo} alt="black-logo" />
          </CustomLink>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography className={classes.title}>{t('mfa.title')}</Typography>
            <Typography className={classes.subtitle}>{t('mfa.subTitle')}</Typography>

            <section className={classes.wrapper}>
              {error && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  &nbsp;
                  {getTranslation(t, error)}
                </span>
              )}
              <Field
                name="code"
                label={t('mfa.inputLabel')}
                placeholder={t('mfa.inputPlaceholder')}
                type="text"
                component={TextInput}
                focused
                className={classes.textField}
                validate={[checkNonLatin, required]}
              />
              <Field name="rememberMe" component={Checkbox} label={t('mfa.rememberMe')} />

              {!isPending && (
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  label={t('mfa.button')}
                  type="submit"
                  className={classes.submitButton}
                />
              )}
              {isPending && (
                <div className={classes.loader}>
                  <Spinner />
                </div>
              )}
            </section>
          </form>
        </div>
      </div>
    </Layout>
  );
};

MfaInput.propTypes = {
  isPending: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.oneOf([null])]),
};

export default compose(reduxForm(), withStyles(styles))(MfaInput);
