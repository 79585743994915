export default {
  root: (theme) => ({
    marginBottom: theme.spacing(2),
    width: theme.spacing(8),
    height: theme.spacing(8),
  }),
  primary: (theme) => ({
    backgroundColor: theme.palette.primary.lightest,
    color: theme.palette.icon.dark,
  }),
};
