import { fontSize } from 'components/CustomThemeProviderMui5/themes/font';

export default {
  rootLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    fontSize: fontSize._16,
  },
  rootLabelDisabled: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  label: (theme) => ({
    paddingTop: theme.spacing(0.4),
    ...theme.typography.body1,
    color: theme.palette.text.dark,
  }),
  labelDisabled: (theme) => ({
    color: theme.palette.textSecondary.lightest,
  }),
  smallSizeLabel: (theme) => ({
    paddingTop: 0,
    ...theme.typography.body2,
  }),
  mediumSizeLabel: (theme) => ({
    paddingTop: theme.spacing(0.4),
    ...theme.typography.body1,
  }),
  errorIcon: (theme) => ({
    color: theme.palette.error.dark,
  }),
  error_small: {
    marginLeft: '3px',
  },
  error_medium: {
    marginLeft: '4px',
  },
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
