import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(actionCreators.getUserSuccess)](state, action) {
      return action.response.data;
    },
    [combineActions(actionCreators.getUserFail)]() {
      return defaultState;
    },
    [actionCreators.getUserPhoneNumberSuccess](state, action) {
      return {
        ...state,
        profile: { ...state.profile, phoneNumber: action.response.data.phoneNumber },
      };
    },
    [actionCreators.getUserLeiInfoSuccess](state, action) {
      return {
        ...state,
        lei: action.response.data,
      };
    },
  },
  defaultState,
);
