import { useEffect, useState } from 'react';

export const useIframe = ({ iframeClass = 'iframeClass', wrapperId = 'iframe', url, styles }) => {
  const newStyles = { width: '100%', height: 'calc(100vh - 155px)', border: 'none', ...styles };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isIframeExists = document.getElementsByClassName(iframeClass)[0];

    if (isIframeExists) {
      return;
    }

    const iframe = document.createElement('iframe');
    const iframeWrapper = document.getElementById(wrapperId);
    iframe.onload = function() {
      iframe.style.display = 'initial';
      setIsLoading(false);
    };

    iframe.src = url;
    iframe.style.display = 'none';
    iframe.style.width = newStyles.width;
    iframe.style.height = newStyles.height;
    if (window.innerHeight < 800) {
      iframe.style.height = '650px';
    }

    iframe.style.border = newStyles.border;
    iframe.className = iframeClass;
    iframeWrapper.appendChild(iframe);
  }, []);

  return [isLoading, setIsLoading];
};
