const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '1em',
    borderRadius: 'none',
    backgroundSize: '200% auto',
    transition: '0.5s',
    '&:hover': {
      backgroundPosition: 'right center',
    },
  },
  buttonLabel: {
    color: 'white',
    fontWeight: 900,
    fontSize: '1em',
    lineHeight: '15px',
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    visibility: 'hidden',
    transition: 'none',
    pointerEvents: 'none',
  },
  visible: {
    visibility: 'visible',
  },
  loader: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
  },
  buttonTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
