import React, { Fragment } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { styles } from './styles';
import { DOWNLOAD_MOBILE_APP_URLS, IFRAME_QRCODE_URL } from 'modules/tradingTools/contants';
import { Link } from 'material-latest';
import TPButton from 'components/TP-UI/TPButton';
import { SIZES } from 'components/TP-UI/constants';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import TPTypography from 'components/TP-UI/TPTypography';
import { isDesktop } from 'react-device-detect';
import TPImage from 'components/TP-UI/TPImage';

const DownloadAutochartistMT4 = () => {
  return (
    <TPGrid container spacing={2}>
      <TPGrid item xs={12} sx={styles.title}>
        Start Using Autochartist
      </TPGrid>
      <TPGrid item xs={12} pb={1} sx={styles.text}>
        The Autochartist Mobile application gives you access to opportunities that have the highest
        probability of hitting your targets.
      </TPGrid>
      <TPGrid item xs={12} pb={2} sx={styles.text}>
        Every opportunity is evaluated against past performance with respect to the pattern type,
        the instrument and the time of day it was identified.
        <br />
        You get notified by push notification when a new trade stop is found.
      </TPGrid>
      {isDesktop ? (
        <Fragment>
          <TPGrid item xs={12} pb={3} sx={styles.title}>
            Download the App
          </TPGrid>
          <TPGrid container item xs={12} alignItems="center">
            <TPGrid
              item
              xs={12}
              sm={9}
              pb={2}
              sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <TPImage
                src={IFRAME_QRCODE_URL}
                sx={{ ml: { xs: 0, sm: '47px' } }}
                style={styles.qrCode}
              />
            </TPGrid>
            <TPGrid
              item
              xs={12}
              sm={9}
              sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center', sm: 'flex-start' } }}
              textAlign="center">
              <TPTypography sx={styles.availableOnPlatform}>
                Available on IOS and Android
              </TPTypography>
            </TPGrid>
          </TPGrid>
        </Fragment>
      ) : (
        <TPGrid
          container
          item
          xs={12}
          sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
          alignItems="center">
          <TPGrid item xs={10} mb={1}>
            <Link
              href={DOWNLOAD_MOBILE_APP_URLS.IOS}
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
              download={true}>
              <TPButton
                sx={{ width: { xs: '100%', sm: '250px' } }}
                primary
                icon={AppleIcon}
                size={SIZES.LARGE}>
                AVAILABLE ON IOS
              </TPButton>
            </Link>
          </TPGrid>
          <TPGrid item xs={10}>
            <Link
              href={DOWNLOAD_MOBILE_APP_URLS.ANDROID}
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
              download={true}>
              <TPButton
                sx={{ width: { xs: '100%', sm: '250px' } }}
                primary
                icon={AndroidIcon}
                size={SIZES.LARGE}>
                AVAILABLE ON ANDROID
              </TPButton>
            </Link>
          </TPGrid>
        </TPGrid>
      )}
    </TPGrid>
  );
};

export default DownloadAutochartistMT4;
