import { handleActions } from 'redux-actions';

import {
  createPresetFail,
  createPresetSuccess,
  deletePresetSuccess,
  editSelectedPresetSuccess,
  getPresetsFail,
  getPresetsSuccess,
  setSelectedPresetSuccess,
} from 'modules/common/Presets/actions';

const defaultState = [];

export default handleActions(
  {
    [getPresetsSuccess](state, action) {
      return action.response.data;
    },
    [getPresetsFail]() {
      return defaultState;
    },
    [setSelectedPresetSuccess](state, action) {
      const selectedPreset = action.response.data;
      const presets = state.map((preset) =>
        preset._id === selectedPreset._id
          ? { ...preset, selected: selectedPreset.selected }
          : { ...preset, selected: false },
      );

      return presets;
    },
    [editSelectedPresetSuccess](state, action) {
      const updatedPreset = action.response.data;
      const presets = state.map((preset) =>
        preset._id === updatedPreset._id ? updatedPreset : preset,
      );

      return presets;
    },
    [createPresetSuccess](state, action) {
      return !action.payload.persistStoreData ? [...state, action.response.data] : state;
    },
    [deletePresetSuccess](state, action) {
      return state.filter((preset) => preset._id !== action.response.data.id);
    },
  },
  defaultState,
);
