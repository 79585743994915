export default {
  root: (theme) => ({
    position: 'relative',
    width: 'fit-content',
    overflow: 'visible',
    padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    borderRadius: '3px !important',
    '&:before': {
      backgroundColor: 'initial',
    },
    '&.Mui-expanded': {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  }),
  elevation: {
    padding: 0,
    '&.Mui-expanded': {
      padding: 0,
      margin: 0,
    },
  },
  filled: (theme) => ({
    transition: 'background-color box-shadow .3s',
    backgroundColor: theme.palette.primary.lightest,
    '&.Mui-expanded': {
      backgroundColor: theme.palette.background.main,
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
  }),
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  loading: {
    opacity: 0.5,
  },
  accordionLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
};
