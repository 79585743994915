import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CLIENT_TYPES,
} from '../../../constants/userNotifications';

export const NOTIFICATION_TYPES = {
  WHATS_NEW: 'WHATS_NEW',
  NOTIFICATION: 'NOTIFICATION',
};

export const CREATE_NOTIFICATION_MODAL = 'CREATE_NOTIFICATION_MODAL';

export const SHOW_NOTIFICATIONS_MODAL = 'SHOW_NOTIFICATIONS_MODAL';

export const CREATE_NOTIFICATION_FORM = 'CREATE_NOTIFICATION_FORM';

export const NOTIFICATION_TYPE = [
  {
    label: 'Notification',
    value: 'NOTIFICATION',
  },
  {
    label: "What's new",
    value: 'WHATS_NEW',
  },
];

export const MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const ROUTES_MAPER = {
  [NOTIFICATION_TYPES.NOTIFICATION]: 'notifications',
  [NOTIFICATION_TYPES.WHATS_NEW]: 'whats-new',
};

export const NOTIFICATION_CATEGORIES_LABELS = {
  [NOTIFICATION_CATEGORIES.MARKET_HOURS_UPDATES]: 'Market Hours Updates',
  [NOTIFICATION_CATEGORIES.NEW_FEATURES_IN_THE_HUB]: 'New Features in the hub',
  [NOTIFICATION_CATEGORIES.NEW_PAYMENT_OPTIONS]: 'New Payment Options',
  [NOTIFICATION_CATEGORIES.PROMOTIONS]: 'Promotions',
  [NOTIFICATION_CATEGORIES.TRADE_IDEAS]: 'Trade Ideas',
};

export const NOTIFICATION_CLIENT_TYPES_LABELS = {
  [NOTIFICATION_CLIENT_TYPES.WHOLESALE]: 'Wholesale',
  [NOTIFICATION_CLIENT_TYPES.PROFESSIONAL]: 'Professional',
  [NOTIFICATION_CLIENT_TYPES.RETAIL]: 'Retail',
  [NOTIFICATION_CLIENT_TYPES.ALL]: 'All',
};
