import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import isNil from 'lodash/isNil';

import TPTypography from 'components/TP-UI/TPTypography';
import TPCopyButton from 'components/TP-UI/TPCopyButton';
import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';
import { getDeepObjectValue } from '../helpers/object';

import styles from './styles';

const TPDescriptionList = ({
  value,
  descriptionProps,
  className,
  showEmpty = true,
  columns,
  size = SIZES.MEDIUM,
}) => {
  return value ? (
    <Box
      component="dl"
      sx={[
        styles.descriptionList,
        className,
        columns && styles[`columns${columns}`],
        size === SIZES.SMALL && styles.smallSize,
      ]}>
      {descriptionProps
        ? descriptionProps.map((prop, index) => {
            let propValue = prop.dataKey ? getDeepObjectValue(value, prop.dataKey) : null;
            if (!isNil(propValue) && prop.format) {
              propValue = prop.format(propValue);
            }
            if (isNil(propValue) && !showEmpty && !prop.renderItem) {
              return null;
            }
            let text = prop.renderItem ? prop.renderItem(value, propValue) : propValue || '\u2014';
            if (isNil(text) && !showEmpty) {
              return null;
            }
            const copy =
              prop.copyToClipboard !== undefined
                ? typeof prop.copyToClipboard === 'function'
                  ? prop.copyToClipboard(value)
                  : prop.copyToClipboard
                : false;

            return (
              <Box
                sx={[styles.descriptionListItem, prop.fullWidth && styles.fullWidth]}
                key={prop.dataKey + index}>
                <TPTypography component="dt" variant="caption" color={MAIN_COLORS.SECONDARY}>
                  {prop.label}
                </TPTypography>
                <TPTypography
                  component="dd"
                  variant={size === SIZES.SMALL ? 'body2' : 'body1'}
                  className={[
                    prop.textStyle && styles[`${prop.textStyle}Text`],
                    prop.wordBreakAll && styles.wordBreakAll,
                  ]}>
                  {copy ? (
                    <>
                      <TPTypography component="span" variant="inherit">
                        {text}
                      </TPTypography>
                      <TPCopyButton size={SIZES.XSMALL} text={text} />
                    </>
                  ) : (
                    <TPTypography component="span" variant="inherit">
                      {text}
                    </TPTypography>
                  )}
                </TPTypography>
              </Box>
            );
          })
        : null}
    </Box>
  ) : null;
};

TPDescriptionList.displayName = 'MuiDescriptionList';

TPDescriptionList.propTypes = {
  /**
   * Data
   */
  value: PropTypes.object,
  /**
   * This value is responsible for showing or hiding the value
   */
  showEmpty: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  /**
   * This value is responsible for showing data in columns, supported 1, 2, 3, 4 columns
   */
  columns: PropTypes.oneOf([1, 2, 3, 4]),
  /**
   * Config for every property, that should be displayed
   */
  descriptionProps: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Name(key) of value property, ex. email
       */
      dataKey: PropTypes.string.isRequired,
      /**
       * Label(title), e.g. Username
       */
      label: PropTypes.node.isRequired,
      /**
       * Text style of property value
       */
      textStyle: PropTypes.oneOf(['bold', '']),
      /**
       * Stretch prop item to container
       */
      fullWidth: PropTypes.bool,
      /**
       * Called when property value is rendered, function (value) => 'formatted string'
       */
      format: PropTypes.func,
      /**
       * Called when property value is rendered, function (item, value) => <Custom component />
       */
      renderItem: PropTypes.func,
      /**
       * Add copy icon button with ability to copy the item value to clipboard
       */
      copyToClipboard: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      /**
       * Apply 'word-break: break-all' style to the item, ensuring that the text will
       * wrap to the next line at any character to prevent overflow.
       */
      wordBreakAll: PropTypes.bool,
    }),
  ).isRequired,
};

export default TPDescriptionList;
