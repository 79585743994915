import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import PrimaryButton from 'modules/common/PrimaryButton';
import { Dialog, Stack } from 'material-latest';

const CustomModal = ({
  classes,
  isModalOpen,
  onClose,
  children,
  className,
  text,
  closeModal,
  customCloseModal,
  isCloseButtonNeeded = false,
  title,
  titleEndAdornment,
  modalClassNames,
  disableEnforceFocus = true,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  fullScreen,
  buttons,
  hideButtons,
}) => {
  const { t } = useTranslation('common');

  const handleCloseClick = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }

    if (customCloseModal) {
      customCloseModal(event);
    } else {
      closeModal(event);
    }

    if (onClose) {
      onClose(event);
    }
  };
  const getButton = (props) => <PrimaryButton key={props.label} size="large" {...props} />;

  return (
    <Dialog
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      open={isModalOpen}
      onClose={handleCloseClick}
      className={classNames(classes.modal, modalClassNames)}>
      <div
        className={classNames(classes.paper, className, { [classes.paperFullScreen]: fullScreen })}>
        {(isCloseButtonNeeded || title) && (
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={4}
            width="100%"
            justifyContent={title ? 'space-between' : 'flex-end'}
            pb={3}>
            {title && (
              <Typography className={classNames(classes.title)}>
                {title}
                {titleEndAdornment && (
                  <div className={classes.titleEndAdornment}>{titleEndAdornment}</div>
                )}
              </Typography>
            )}
            {isCloseButtonNeeded && (
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={customCloseModal || closeModal}
                color="primary">
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
        {!text ? (
          children
        ) : (
          <div className={classes.container}>
            <Typography className={classes.infoText}>{text}</Typography>
            {!hideButtons && !buttons && (
              <div className={classes.buttonContainer}>
                {getButton({
                  label: t('buttons.gotIt'),
                  color: 'primary',
                  onClick: closeModal,
                  className: classNames({
                    [classes.submitButton]: true,
                  }),
                })}
              </div>
            )}
          </div>
        )}
        {buttons && (
          <div className={classes.buttonContainer}>{buttons.map((props) => getButton(props))}</div>
        )}
      </div>
    </Dialog>
  );
};

CustomModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isCloseButtonNeeded: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  className: PropTypes.string,
  modalClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  closeModal: PropTypes.func,
  customCloseModal: PropTypes.func,
  text: PropTypes.string,
  disableEnforceFocus: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  title: PropTypes.string,
  titleEndAdornment: PropTypes.node,
  fullScreen: PropTypes.bool,
  buttons: PropTypes.array,
  hideButtons: PropTypes.bool,
};

export default withStyles(styles)(CustomModal);
