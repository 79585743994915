export default {
  value: (theme) => ({
    display: 'inline-block',
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '50%',
    padding: '5px 0',
    textAlign: 'center',
    fontSize: '10px',
    width: '22px',
    height: '22px',
    marginLeft: theme.spacing(1),
  }),
};
