import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'utils/compositeTranslationHandler';
import NavigationMenuListItem from '../NavigationMenuListItem';
import styles from './styles';

const renderMenuListItem = (classes, selectedRoute, t, onClick, isCollapsed, item) => (
  <NavigationMenuListItem
    id={item.id}
    key={item.id}
    label={getTranslation(t, item.label)}
    isSelected={selectedRoute.includes(item.url)}
    onClick={onClick}
    icon={item.icon}
    link={item.url}
    className={classnames(classes.nestedItem, { [classes.nestedItemCollapsed]: isCollapsed })}
    iconClassName={classes.nestedIcon}
    isCollapsed={isCollapsed}
  />
);

const NavigationMenuNestedListItem = ({
  id,
  classes,
  selectedRoute,
  label,
  icon,
  items,
  onClick,
  isCollapsed,
}) => {
  const { t } = useTranslation();
  const isOpen = items.some((item) => selectedRoute.includes(item.url));
  const [open, setOpen] = React.useState(isOpen);
  const IconComponent = icon;
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={classnames(classes.root, { [classes.rootOpen]: isCollapsed ? false : open })}
        id={id}>
        {!isCollapsed && (
          <ListItem
            button
            onClick={handleClick}
            className={classnames(classes.listItem, { [classes.listItemCollapsed]: isCollapsed })}>
            <ListItemIcon className={classnames({ [classes.iconContainerCollapsed]: isCollapsed })}>
              {typeof icon === 'string' ? (
                <img src={icon} alt={label} className={classes.icon} />
              ) : (
                <IconComponent
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: (theme) => ({
                      color: theme.palette.icon.dark,
                    }),
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText
              className={classes.textContainer}
              disableTypography
              primary={<Typography className={classes.label}>{label}</Typography>}
            />
            {open ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        )}
      </div>
      <Collapse in={open || isCollapsed} timeout="auto" unmountOnExit>
        {items
          .filter((item) => item.visible)
          .map(renderMenuListItem.bind(null, classes, selectedRoute, t, onClick, isCollapsed))}
      </Collapse>
    </>
  );
};

NavigationMenuNestedListItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedRoute: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

export default withStyles(styles)(NavigationMenuNestedListItem);
