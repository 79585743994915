import React from 'react';
import { IFRAME_URLS } from '../contants';
import MainLayoutContainer from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';

const CorrelatingContainer = () => {
  const { t } = useTranslation('tradingTools');

  return (
    <MainLayoutContainer title={t('titles.correlating')}>
      <DocumentTitle title={t('titles.correlating')} />
      <iframe src={IFRAME_URLS.CORRELATING} style={styles.iframe}></iframe>
    </MainLayoutContainer>
  );
};

export default CorrelatingContainer;
