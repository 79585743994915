import React from 'react';
import { IFRAME_URLS } from '../contants';
import MainLayoutContainer from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import { useIframe } from '../hooks';
import Spinner from 'modules/common/Spinner';

const PerformanceStatisticsContainer = () => {
  const { t } = useTranslation('tradingTools');
  const [isLoading] = useIframe({ url: IFRAME_URLS.PERFORMANCE_STATISTICS });

  return (
    <MainLayoutContainer title={t('titles.performanceStatistics')}>
      <DocumentTitle title={t('titles.performanceStatistics')} />
      <div id="iframe" style={styles.iframe}>
        {isLoading && (
          <div style={styles.loader}>
            <Spinner />
          </div>
        )}
      </div>
    </MainLayoutContainer>
  );
};

export default PerformanceStatisticsContainer;
