import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'material-latest/useMediaQuery';

import useMobileLayout from 'hooks/useMobileLayout';
import TPButton from 'components/TP-UI/TPButton';

const HeaderButtons = ({ items = [] }) => {
  const isSmallScreenSize = useMediaQuery((theme) => theme.breakpoints.down(430));
  const mobile = useMobileLayout();

  return items.map(({ label, ...buttonProps }, index) => (
    <TPButton
      {...buttonProps}
      key={`header-button-${index}`}
      size={mobile ? 'medium' : 'large'}
      fullWidth={isSmallScreenSize}>
      {label}
    </TPButton>
  ));
};

HeaderButtons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      secondary: PropTypes.bool,
      alternative: PropTypes.bool,
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
};

export default HeaderButtons;
