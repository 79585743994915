import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-latest/CircularProgress';
import LinearProgress from 'material-latest/LinearProgress';
import Box from 'material-latest/Box';

import TPTypography from 'components/TP-UI/TPTypography';
import { MAIN_COLORS } from 'components/TP-UI/constants';

import styles from './styles';

const TPProgressBar = ({
  primary,
  progress = 0,
  label = '',
  labelColor = MAIN_COLORS.SECONDARY,
  labelVariant = 'subtitle2',
  thickness = 4,
  circle = false,
  diameter = 72,
  ...props
}) => {
  return circle ? (
    <Box sx={[styles.circleWrapper, { width: diameter }]}>
      <CircularProgress
        variant="determinate"
        sx={styles.circleTrack}
        size={diameter}
        thickness={thickness}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        sx={[styles.circleBar, !primary && styles.circleBarSecondary]}
        size={diameter}
        thickness={thickness}
        value={progress}
        {...props}
      />
      <TPTypography
        component="p"
        variant={labelVariant}
        color={labelColor}
        className={styles.circleLabel}>
        {label}
      </TPTypography>
    </Box>
  ) : (
    <>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={[styles.lineTrack, !primary && styles.lineBarSecondary]}
        style={{ height: thickness }}
        {...props}
      />
      <TPTypography
        component="p"
        variant={labelVariant}
        color={labelColor}
        className={styles.lineLabel}>
        {label}
      </TPTypography>
    </>
  );
};

TPProgressBar.propTypes = {
  /**
   * Primary variant of progress bar.
   */
  primary: PropTypes.bool,
  /**
   * Progress value in percentage to display indicator.
   */
  progress: PropTypes.number,
  /**
   * Label for progress indicator.
   */
  label: PropTypes.string,
  /**
   * Color of progress indicator.
   */
  labelColor: TPTypography.propTypes.color,
  /**
   * Variant of progress indicator.
   */
  labelVariant: TPTypography.propTypes.variant,
  /**
   * Progress line/circle width in pixels.
   */
  thickness: PropTypes.number,
  /**
   * If `true`, circle progress will be displayed.
   */
  circle: PropTypes.bool,
  /**
   * Circle size in pixels (height and width).
   */
  diameter: PropTypes.number,
};

export default TPProgressBar;
