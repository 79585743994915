import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import {
  cleanupFusionPlusUsers,
  getFusionPlusUsersFail,
  getFusionPlusUsersSuccess,
} from '../actions';

const defaultState = {
  users: [],
  totalNumber: undefined,
  query: null,
};

const getActive = (user) => {
  if (user.type === 'invite') {
    return user.enabled ? 'Yes' : 'No';
  }

  return user.copierMode !== 'off' ? 'Yes' : 'No';
};

export default handleActions(
  {
    [getFusionPlusUsersSuccess](state, action) {
      return {
        ...state,
        query: action.payload,
        totalNumber: action.response.data.totalNumber,
        users: action.response.data.users.map((user) => {
          return {
            ...user,
            active: getActive(user),
            leadUserId: {
              ...user.leadUserId,
              userName: `${get(user, 'leadUserId.profile.firstName', '')} ${get(
                user,
                'leadUserId.profile.lastName',
                '',
              )}`,
            },
            followerUserId: {
              ...user.followerUserId,
              userName: `${get(user, 'followerUserId.profile.firstName', '')} ${get(
                user,
                'followerUserId.profile.lastName',
                '',
              )}`,
            },
          };
        }),
      };
    },
    [getFusionPlusUsersFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupFusionPlusUsers]() {
      return defaultState;
    },
  },
  defaultState,
);
