export const WITHDRAW_TRANSFER_FORM = 'WITHDRAW_TRANSFER_FORM';
export const WITHDRAW_UNIONPAY_NAMES = 'WITHDRAW_UNIONPAY_NAMES';
export const WITHDRAW_VISA_FORM = 'WITHDRAW_VISA_FORM';
export const DEFAULT_CURRENCY = 'USD';
export const WITHDRAW_SEARCH_FORM = 'WITHDRAW_SEARCH_FORM';
export const WITHDRAW_SKRILL_FORM = 'WITHDRAW_SKRILL_FORM';
export const WITHDRAW_NETELLER_FORM = 'WITHDRAW_NETELLER_FORM';
export const WITHDRAW_PAYPAL_FORM = 'WITHDRAW_PAYPAL_FORM';
export const WITHDRAW_PAYID_FORM = 'WITHDRAW_PAYID_FORM';
export const WITHDRAW_PERFECT_MONEY_FORM = 'WITHDRAW_PERFECT_MONEY_FORM';
export const WITHDRAW_INTERAC_E_TRANSFER_OUTBOUND_FORM =
  'WITHDRAW_INTERAC_E_TRANSFER_OUTBOUND_FORM';
export const WITHDRAW_JETON_FORM = 'WITHDRAW_JETON_FORM';
export const WITHDRAW_THUNDERXPAY_FORM = 'WITHDRAW_THUNDERXPAY_FORM';
export const WITHDRAW_FINRAX_FORM = 'WITHDRAW_FINRAX_FORM';
export const WITHDRAW_ASTROPAY_FORM = 'WITHDRAW_ASTROPAY_FORM';
export const WITHDRAW_DRAGONPAY_FORM = 'WITHDRAW_DRAGONPAY_FORM';
export const WITHDRAW_DOKUPAY_FORM = 'WITHDRAW_DOKUPAY_FORM';
export const WITHDRAW_HYPERWALLET_FORM = 'WITHDRAW_HYPERWALLET_FORM';
export const WITHDRAW_HYPERWALLET_USER_STATE_FORM = 'WITHDRAW_HYPERWALLET_USER_STATE_FORM';
export const WITHDRAW_HYPERWALLET_STATUS_REQUEST_CHANGE_FORM =
  'WITHDRAW_HYPERWALLET_STATUS_REQUEST_CHANGE_FORM';
export const WITHDRAW_HYPERWALLET_USER_ADDRESS_FORM = 'WITHDRAW_HYPERWALLET_USER_ADDRESS_FORM';

export const WITHDRAW_METHODS = {
  unionpay: 'UnionPay/Local Bank Transfer',
  visa: 'Visa/Mastercard',
  bank: 'Bank Wire/Transfer',
  payid: 'PayID',
  // hyperwallet: 'Local Bank Transfer (SEPA)',
  // thunderXPay: 'Thai Local Bank Transfer',
  localBank: 'Local Bank Transfer',
  skrill: 'Skrill',
  neteller: 'Neteller',
  paypal: 'PayPal',
  // perfectMoney: 'Perfect Money',
  interacEto: 'Interac e-Transfer Outbound',
  finrax: 'Crypto',
  astropay: 'Astropay',
  dragonpay: 'Dragonpay',
  dokupay: 'Dokupay',
  jeton: 'Jeton',
};

export const MIN_WITHDRAWAlS = {
  usd: 10,
  gbp: 10,
  eur: 10,
  aud: 10,
  cad: 15,
  hkd: 100,
  jpy: 1120,
  nzd: 15,
  sgd: 15,
};
export const MIN_THUNDERXPAY_WITHDRAWALS = { ...MIN_WITHDRAWAlS, thb: 300 };

export const MAX_WITHDRAWAlS = {
  usd: 9999,
  gbp: 9999,
  eur: 9999,
  aud: 9999,
  cad: 9999,
  hkd: 9999,
  jpy: 9999,
  nzd: 9999,
  sgd: 9999,
};
export const MAX_THUNDERXPAY_WITHDRAWALS = { ...MAX_WITHDRAWAlS, thb: 500000 };

export const MIN_INTERNATIONAL_WITHDRAWAlS = {
  usd: 35,
  gbp: 30,
  eur: 35,
  aud: 50,
  cad: 50,
  hkd: 280,
  jpy: 4000,
  nzd: 55,
  sgd: 50,
};

export const WITHDRAW_TRANSFER_MODAL = 'WITHDRAW_TRANSFER_MODAL';

export const WITHDRAW_RESULT_MODAL_SUCCESS = 'WITHDRAW_RESULT_MODAL_SUCCESS';
export const WITHDRAW_RESULT_MODAL_ERROR = 'WITHDRAW_RESULT_MODAL_ERROR';

export const CLIENT_CONFIRM_WITHDRAW_MODAL = 'CLIENT_CONFIRM_WITHDRAW_MODAL';

export const SAVE_BANK_DETAILS_MODAL = 'OPEN_SAVE_BANK_DETAILS_MODAL';

export const HIDE_SKRILL_AND_NETELLER_FOR_COUNTRIES = new Set([
  'Afghanistan',
  'American Samoa',
  'Angola',
  'Antigua and Barbuda',
  'Barbados',
  'Benin',
  'Bouvet Island',
  'Burkina Faso',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Cocos Islands',
  'Comoros',
  'Cuba',
  'Curacao',
  'Democratic republic of Congo',
  'Djibouti',
  'Eritrea',
  'Faroe Islands',
  'French Polynesia',
  'French Southern Territories',
  'Gambia',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guinea-Bissau',
  'Guyana',
  'Heard Island and McDonald Islands',
  'Iran',
  'Iraq',
  'Japan',
  'Kyrgstan',
  'Laos',
  'Libya',
  'Macau SAR China',
  'Martinique',
  'Mayotte',
  'Micronesia',
  'Myanmar',
  'Namibia',
  'New Caledonia',
  'Niger',
  'Norfolk Island',
  'North Korea',
  'Northern Mariana Islands',
  'Palau',
  'Palestine',
  'Pitcairn Islands',
  'Republic of Congo',
  'Reunion',
  'Rwanda',
  'Saint Helana',
  'Samoa',
  'Seychelles',
  'Sierra Leone',
  'Somalia',
  'South Sudan',
  'Spain',
  'Sudan',
  'Suriname',
  'Syria',
  'São Tomé and Principe',
  'Tajikistan',
  'Togo',
  'Tokelau',
  'Tonga',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Ukraine',
  'United Kingdom',
  'Vatican City',
  'Wallis and Furtuna',
  'Western Sahara',
  'Yemen',
]);

export const HIDE_PAYPAL_FOR_COUNTRIES = new Set([
  'Algeria',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Azerbaijan Republic',
  'Bahamas',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of',
  'Congo, Republic of',
  'Cook Islands',
  'Costa Rica',
  'Côte d’Ivoire',
  'Democratic Republic of Congo',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'El Salvador',
  'Eritrea',
  'Ethiopia',
  'Falkland Islands',
  'Gabon Republic',
  'Gambia',
  'Grenada',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'India',
  'Jamaica',
  'Japan',
  'Kiribati',
  'Kyrgyzstan',
  'Laos',
  'Macedonia',
  'Madagascar',
  'Maldives',
  'Mali',
  'Marshall Islands',
  'Mauritania',
  'Mayotte',
  'Micronesia, Federated States of',
  'Mongolia',
  'Monserrat',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands Antilles',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Pitcairn Islands',
  'Puerto Rico',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Sierra Leone',
  'Solomon Islands',
  'Somalia',
  'South Georgia and the South Sandwich Islands',
  'Sri Lanka',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Tajikistan',
  'Tanzania',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'USA',
  'Uganda',
  'Ukraine',
  'Vanuatu',
  'Venezuela',
  'Virgin Islands (British)',
  'Virgin Islands (USA)',
  'Wallis and Futuna Islands',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]);

export const INTERAC_ETO_ALLOWED_COUNTRIES = new Set(['Canada']);
export const MONOOVA_ALLOWED_COUNTRIES = new Set(['Australia']);
export const ENTER_MANUALLY = 'ENTER_MANUALLY';

export const UNIONPAY_ALLOWED_COUNTRIES = new Set([]);
// export const THUNDERXPAY_ALLOWED_COUNTRIES = new Set(['Thailand']);
export const HIDE_FINRAX_FOR_COUNTRIES = new Set([
  'Afghanistan',
  'Albania',
  'Barbados',
  'Belarus',
  'Botswana',
  'Burkina Faso',
  'Cambodia',
  'Cayman Islands',
  'Congo',
  'Cuba',
  'Ghana',
  'Haiti',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Jamaica',
  'Jordan',
  "Korea (Democratic People's Republic of)",
  'Mali',
  'Mauritius',
  'Mongolia',
  'Morocco',
  'Myanmar',
  'Nicaragua',
  'Pakistan',
  'Panama',
  'Russian Federation',
  'Senegal',
  'South Sudan',
  'Syrian Arab Republic',
  'Bahamas',
  'Trinidad and Tobago',
  'Uganda',
  'Vanuatu',
  'Yemen',
  'Zimbabwe',
]);
export const HIDE_BANKWIRE_FOR_COUNTRIES = new Set([]);
export const DRAGONPAY_ALLOWED_COUNTRIES = new Set(['Philippines']);
export const DOKUPAY_ALLOWED_COUNTRIES = new Set([]);
export const HIDE_WITHDRAW_CARD_FOR_COUNTRIES = new Set([
  'Bermuda',
  'Congo',
  'French Guiana',
  'Montserrat',
  'Nicaragua',
  'Suriname',
  'Tunisia',
  'US Virgin Islands',
  'Venezuela',
  'Australia',
  'United Kingdom of Great Britain and Northern Ireland',
]);

export const HYPERWALLET_ALLOWED_COUNTRIES = new Set([
  'American Samoa',
  'Australia',
  'Austria',
  'Belgium',
  'Bulgaria',
  'Canada',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Guadeloupe',
  'Guernsey',
  'Hungary',
  'Iceland',
  'Ireland',
  'Isle of Man',
  'Italy',
  'Jersey',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Martinique',
  'Mayotte',
  'Monaco',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Romania',
  'Saint Barth\u00e9lemy',
  'San Marino',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Saint Pierre and Miquelon',
  'Sweden',
  'Switzerland',
  'United Kingdom',
  'United Kingdom of Great Britain and Northern Ireland',
  'United States of America',
]);

export const HYPERWALLET_ALLOWED_TEST_COUNTRIES = new Set([
  'Germany',
  'Austria',
  'Italy',
  'Greece',
]);

export const PAYID_TYPES = [
  { value: 'Email', label: 'Email' },
  { value: 'PhoneNumber', label: 'Phone Number' },
  // { value: 'OrganisationId', label: 'Organisation Id' },
  // { value: 'ABN', label: 'ABN' },
  // { value: 'ACN', label: 'ACN' },
];
