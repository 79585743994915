export default {
  root: (theme) => ({
    '& > *': {
      marginTop: theme.spacing(1),
    },
    '& > *:first-child': {
      marginTop: 0,
    },
  }),
};
