import get from 'lodash/get';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { change, initialize } from 'redux-form';

import * as actionCreators from '../actions';
import { getAccountsRequest } from '../actions';
import { getActiveAccountsTab } from '../selectors';
import {
  getLeverageByHub,
  getUserId,
  getUserIsRejected,
  getUserName,
  isFirstLoginSinceRejection,
} from 'modules/auth/selectors';
import {
  ADD_ACCOUNT_FORM,
  EDIT_ACCOUNT_FORM,
  FUND_DEMO_ACCOUNT_FORM,
  HIDE_ACCOUNT_MODAL,
  MIGRATION_MODAL,
  REJECTION_MODAL,
  UNDO_ARCHIVE_FORM,
  UNDO_ARCHIVE_MODAL,
} from '../constants';
import * as notificationsActions from 'modules/notifications/actions';
import * as menuActions from 'modules/menu/actions';
import { getUpdatedUserSuccess } from 'modules/menu/actions';
// import { take } from 'redux-saga/dist/redux-saga-effects-npm-proxy.cjs';
import * as signInActionCreators from 'modules/auth/pages/SignIn/actions';
import * as modalActions from 'modules/common/CustomModal/actions';
import * as migrationActionCreators from 'modules/auth/pages/Migration/actions';
import { ACCOUNT_SERVERS } from 'constants/index';

function* reloadAccounts() {
  let activeAccountTab = yield select(getActiveAccountsTab);
  const params = {
    isLive:
      activeAccountTab === ACCOUNT_SERVERS.LIVE || activeAccountTab === ACCOUNT_SERVERS.REBATE,
  };

  yield put(getAccountsRequest(params));
}

function* hideDialog() {
  yield put(actionCreators.accountsHideDialog());
}

function* initEditDialog(action) {
  const leverages = yield select(getLeverageByHub);
  const { leverage } = action.payload;
  let initData = {
    _id: action.payload._id,
    leverage: action.payload.leverage,
    accountNickname: action.payload.accountNickname,
    password: '',
    confirmPassword: '',
    isRebate: action.payload.isRebate,
  };

  if (!leverages.find((item) => item.value === leverage)) {
    delete initData.leverage;
  }

  yield put(initialize(EDIT_ACCOUNT_FORM, initData));
}

function* initFundDemoDialog(action) {
  let initData = {
    accountId: action.payload._id,
    amount: '',
  };

  yield put(initialize(FUND_DEMO_ACCOUNT_FORM, initData));
}

function* createAccountSuccess(action) {
  if (action.payload.accountServer === ACCOUNT_SERVERS.LIVE) {
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'accounts:page.notifications.tradingAccountCreated',
      }),
    );
  } else {
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'accounts:page.notifications.demoAccountCreated',
      }),
    );
  }

  let userName = yield select(getUserName);

  if (!userName) {
    let userId = yield select(getUserId);
    yield put(menuActions.getUpdatedUserRequest(userId));
  }
}

function* createAccountFail() {
  yield put(
    notificationsActions.showNotificationError({
      key: 'accounts:page.notifications.failedCreateAccount',
    }),
  );
}

function* updateAccountSuccess() {
  yield put(
    notificationsActions.showNotificationInfo({ key: 'accounts:page.notifications.changesSaved' }),
  );
}

function* updateAccountFail() {
  yield put(
    notificationsActions.showNotificationError({
      key: 'accounts:page.notifications.failedUpdateAccount',
    }),
  );
}

function* fundDemoAccountSuccess() {
  yield put(
    notificationsActions.showNotificationInfo({ key: 'accounts:page.notifications.changesSaved' }),
  );
}

function* fundDemoAccountFail(action) {
  const errorMessage = get(action, 'error.data.message', 'Error');
  yield put(notificationsActions.showNotificationError(errorMessage));
}

function* checkRejectionAndOpenPopup() {
  yield take(getUpdatedUserSuccess);
  const isUserRejected = yield select(getUserIsRejected);
  const showRejectionPopup = yield select(isFirstLoginSinceRejection);
  if (isUserRejected && showRejectionPopup) {
    yield put(modalActions.toggleModal(REJECTION_MODAL));
  }
}

function* showMigrationModal() {
  yield put(modalActions.openModal(MIGRATION_MODAL));
}

function* hideAccountOpen() {
  yield put(modalActions.openModal(HIDE_ACCOUNT_MODAL));
}

function* hideAccountClose() {
  yield put(modalActions.closeModal(HIDE_ACCOUNT_MODAL));
}

function* hideAccountSuccess() {
  yield put(modalActions.closeModal(HIDE_ACCOUNT_MODAL));
}

function* onUnarchiveAccountModal(action) {
  yield put(modalActions.openModal(UNDO_ARCHIVE_MODAL));
  yield put(change(UNDO_ARCHIVE_FORM, 'login', action.payload));
}

function* onUnarchiveAccountSuccess() {
  yield put(modalActions.closeModal(UNDO_ARCHIVE_MODAL));
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'accounts:page.notifications.reactivateRequestSent',
    }),
  );
}
function* onUnarchiveAccountFail() {
  yield put(modalActions.closeModal(UNDO_ARCHIVE_MODAL));
  yield put(
    notificationsActions.showNotificationError({
      key: 'accounts:page.notifications.failedReActiveAccount',
    }),
  );
}

function* onRequestAdditionalAccountSuccess() {
  yield put(change(ADD_ACCOUNT_FORM, 'requestAccountMessage', null));
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'accounts:page.notifications.requestAccount',
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(
      [
        actionCreators.accountsPageOpened,
        actionCreators.accountsTabChanged,
        actionCreators.createAccountSuccess,
        actionCreators.updateAccountSuccess,
        actionCreators.fundDemoAccountSuccess,
      ],
      reloadAccounts,
    ),
    takeEvery(actionCreators.createAccountSuccess, createAccountSuccess),
    takeEvery(actionCreators.createAccountFail, createAccountFail),
    takeEvery(actionCreators.updateAccountSuccess, updateAccountSuccess),
    takeEvery(actionCreators.updateAccountFail, updateAccountFail),
    takeEvery(actionCreators.fundDemoAccountFail, fundDemoAccountFail),
    takeEvery(actionCreators.fundDemoAccountSuccess, fundDemoAccountSuccess),
    takeEvery(actionCreators.accountsShowEditDialog, initEditDialog),
    takeEvery(actionCreators.accountsShowFundDemoDialog, initFundDemoDialog),
    takeEvery(
      [
        actionCreators.createAccountSuccess,
        actionCreators.updateAccountSuccess,
        actionCreators.fundDemoAccountSuccess,
        actionCreators.requestAdditionalAccountSuccess,
      ],
      hideDialog,
    ),
    takeEvery([signInActionCreators.signinSuccess], checkRejectionAndOpenPopup),
    takeEvery([migrationActionCreators.userMigrationSuccess], showMigrationModal),
    takeEvery(actionCreators.hideAccountOpen, hideAccountOpen),
    takeEvery(actionCreators.hideAccountClose, hideAccountClose),
    takeEvery(actionCreators.hideAccountSuccess, hideAccountSuccess),

    takeEvery(actionCreators.unarchiveAccountModal, onUnarchiveAccountModal),
    takeEvery(actionCreators.unarchiveAccountSuccess, onUnarchiveAccountSuccess),
    takeEvery(actionCreators.unarchiveAccountFail, onUnarchiveAccountFail),
    takeEvery(actionCreators.requestAdditionalAccountSuccess, onRequestAdditionalAccountSuccess),
  ]);
}
