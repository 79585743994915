import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import styles from './styles';

const TPTabBadge = ({ value }) => {
  const displayedValue = value > 99 ? '99+' : value;

  return (
    <Box component="span" sx={styles.value}>
      {displayedValue}
    </Box>
  );
};

TPTabBadge.propTypes = {
  value: PropTypes.number,
};

export default TPTabBadge;
