export const DIALOG_NAME = {
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  FUND_DEMO_ACCOUNT: 'FUND_DEMO_ACCOUNT',
};

export const ADD_ACCOUNT_FORM = 'ADD_ACCOUNT_FORM';
export const EDIT_ACCOUNT_FORM = 'EDIT_ACCOUNT_FORM';
export const FUND_DEMO_ACCOUNT_FORM = 'FUND_DEMO_ACCOUNT_FORM';
export const PRO_OR_RETAIL_FORM = 'PRO_OR_RETAIL_FORM';
export const PRO_DECLARATION_FORM = 'PRO_DECLARATION_FORM';
export const RETAIL_DECLARATION_FORM = 'RETAIL_DECLARATION_FORM';

export const ACCOUNTS_WELCOME_MODAL = 'ACCOUNTS_WELCOME_MODAL';
export const REJECTION_MODAL = 'REJECTION_MODAL';

export const MIGRATION_MODAL = 'MIGRATION_MODAL';

export const FUND_OPTIONS = [
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdraw', label: 'Withdraw' },
];

export const HIDE_ACCOUNT_MODAL = 'HIDE_ACCOUNT_MODAL';

export const PRO_OR_RETAIL_MODAL = 'PRO_OR_RETAIL_MODAL';

export const READY_TO_CREATE_ACCOUNT_MODAL = 'READY_TO_CREATE_ACCOUNT_MODAL';

export const CLOSE_PRO_MODAL = 'CLOSE_PRO_MODAL';

export const PRO_DECLARATION_MODAL = 'PRO_DECLARATION_MODAL';

export const RETAIL_DECLARATION_MODAL = 'RETAIL_DECLARATION_MODAL';

export const UNDO_ARCHIVE_MODAL = 'UNDO_ARCHIVE_MODAL';
export const UNDO_ARCHIVE_FORM = 'UNDO_ARCHIVE_FORM';

export const ACCOUNTS_LIMIT = {
  PLAIN: 10,
  VIP: 1000,
};
export const DEMO_ACCOUNTS_LIMIT = {
  PLAIN: 10,
  VIP: 1000,
};

export const CURRENT_LANGUAGE = {
  THAI: 'th',
  EN: 'en',
  CH: 'ch',
  CHM: 'chm',
};
