import api from 'modules/api/index';
import { isUndefined } from 'lodash';

export const createPresetRequest = (payload) => {
  return api.post(`/presets/create-preset`, payload);
};

export const getPresetsRequest = () => {
  return api.get(`/presets`);
};

export const setSelectedPresetRequest = (payload) => {
  if (isUndefined(payload.selected)) {
    payload.selected = true;
  }

  return api.post(`/presets/select-preset`, payload);
};

export const deletePresetRequest = (id) => {
  return api.delete(`/presets/delete-preset/${id}`);
};

export const editSelectedPresetRequest = (payload) => {
  const { id } = payload;
  return api.post(`/presets/edit-selected-preset/${id}`, payload);
};
