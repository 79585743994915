import { createAction } from 'redux-actions';

export const createPresetRequest = createAction('CREATE_PRESET_REQUEST');
export const createPresetSuccess = createAction('CREATE_PRESET_SUCCESS');
export const createPresetFail = createAction('CREATE_PRESET_FAIL');

export const getPresetsRequest = createAction('GET_PRESETS_REQUEST');
export const getPresetsSuccess = createAction('GET_PRESETS_SUCCESS');
export const getPresetsFail = createAction('GET_PRESETS_FAIL');

export const setSelectedPresetRequest = createAction('SET_SELECTED_PRESET_REQUEST');
export const setSelectedPresetSuccess = createAction('SET_SELECTED_PRESET_SUCCESS');
export const setSelectedPresetFail = createAction('SET_SELECTED_PRESET_FAIL');

export const deletePresetRequest = createAction('DELETE_PRESET_REQUEST');
export const deletePresetSuccess = createAction('DELETE_PRESET_SUCCESS');
export const deletePresetFail = createAction('DELETE_PRESET_FAIL');

export const editSelectedPresetRequest = createAction('EDIT_SELECTED_PRESET_REQUEST');
export const editSelectedPresetSuccess = createAction('EDIT_SELECTED_PRESET_SUCCESS');
export const editSelectedPresetFail = createAction('EDIT_SELECTED_PRESET_FAIL');
