import moment from 'moment';
import { get } from 'lodash';
import { DEFAULT_YES_OPTION, EMPTY_FORM, FORM_DEFAULTS } from '../constants';

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }
  return moment(date)
    .utc()
    .format('DD MMM YYYY HH:mm:ss');
};

export const getMasterAccountName = (invite) => {
  if (invite.leadAccountId) {
    return `${get(invite, 'leadAccountId.accountNickname', '')} ${get(
      invite,
      'leadAccountId.mt4login',
      '',
    )}`;
  }
  return `${get(invite, 'user.firstName')} ${get(invite, 'user.lastName')} ${invite.mt4login}`;
};

export const getFollowerAccountName = (invite) => {
  if (invite.followerAccountId) {
    return `${get(invite, 'followerAccountId.accountNickname', '')} ${get(
      invite,
      'followerAccountId.mt4login',
      '',
    )}`;
  }
  return `${get(invite, 'copier.followerAccountId.accountNickname', '')} ${get(
    invite,
    'copier.followerAccountId.mt4login',
    '',
  )}`;
};

export const getInviteProperty = (invite, key) => {
  return get(invite, `copier.${key}`, get(invite, key));
};

export const assignTradesUniqueIndex = (trades) => {
  return [...trades].map((trade, index) => ({ ...trade, index }));
};

export const getNewSettingsForCopiers = (
  data,
  follower,
  defaultMoneyManagementTypeId,
  defaultOpenTradesTypeId,
  defaultInstruments,
) => {
  const followerSettings = setCopierSettings(
    follower,
    defaultMoneyManagementTypeId,
    defaultOpenTradesTypeId,
    defaultInstruments,
  );
  followerSettings.enabled = follower.enabled;

  for (let key in data) {
    followerSettings[key] = data[key] ?? followerSettings[key];
  }

  followerSettings.feeValue = followerSettings.useFee ? followerSettings.feeValue : 0;

  return followerSettings;
};

export const getCopierSettings = (formData) => {
  const data = { ...formData };
  data.feeValue = data.useFee === DEFAULT_YES_OPTION.value ? data.feeValue : 0;

  return data;
};

export const setCopierSettings = (
  copySettings,
  defaultMoneyManagementTypeId,
  defaultOpenTradesTypeId,
  defaultInstruments,
) => {
  const data = {
    copierMode: get(copySettings, 'copierMode', FORM_DEFAULTS.copierMode),
    moneyManagementTypeId: get(copySettings, 'moneyManagementTypeId', defaultMoneyManagementTypeId),
    percentageToMirror: get(copySettings, 'percentageToMirror', FORM_DEFAULTS.percentageToMirror),
    fixedSize: get(copySettings, 'fixedSize', FORM_DEFAULTS.fixedSize),
    reverseTrade: get(copySettings, 'reverseTrade', FORM_DEFAULTS.reverseTrade),
    copyPendingOrders: get(copySettings, 'copyPendingOrders', FORM_DEFAULTS.copyPendingOrders),
    copyStops: get(copySettings, 'copyStops', FORM_DEFAULTS.copyStops),
    copyOpenTradesMethodId: get(copySettings, 'copyOpenTradesMethodId', defaultOpenTradesTypeId),
    emailNotifications: get(copySettings, 'emailNotifications', FORM_DEFAULTS.emailNotifications),
    useFee: get(copySettings, 'useFee', FORM_DEFAULTS.useFee),
    feeValue: get(copySettings, 'feeValue', FORM_DEFAULTS.feeValue),
  };

  const instrumentsIds = new Set(copySettings.instrumentsIds || defaultInstruments);
  data.instrumentsIds = Array.from(instrumentsIds);
  data.isEnableAllSymbols = defaultInstruments.every((defaultInstrument) =>
    instrumentsIds.has(defaultInstrument),
  );

  return data;
};

export const resetCopierSettings = () => {
  let data = { ...EMPTY_FORM };

  return data;
};
