export default {
  root: (theme) => ({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    minHeight: '48px',
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
      '&.Mui-expanded': {
        margin: theme.spacing(0),
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: theme.spacing(-0.25),
      padding: 0,
    },
  }),
  elevation: {
    minHeight: '32px',
    '&.Mui-expanded': {
      minHeight: '32px',
    },
  },
  titleIcon: (theme) => ({
    display: 'flex',
    fill: theme.palette.text.dark,
  }),
};
