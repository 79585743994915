import { useMemo } from 'react';
import mergeUrlPaths from '../../../../utils/mergeUrlPaths';
import { Link } from 'react-router-dom';
import useMatchUrl from '../../../../hooks/navigation/useMatchUrl';

export default function useTabs(tabs, nav) {
  const currentBaseUrl = useMatchUrl();

  return useMemo(() => {
    return tabs.map((tab, index) => {
      const resultPath = mergeUrlPaths([currentBaseUrl, tab.path], true);
      const props = nav
        ? {
            to: resultPath,
            component: Link,
          }
        : {};
      return { ...tab, itemKey: index, path: getPath(tab), ...props };
    });
  }, [tabs, nav, currentBaseUrl]);
}

function getPath(tabProps) {
  if ('path' in tabProps) {
    return tabProps.path;
  }

  if (typeof tabProps.label === 'string') {
    return tabProps.label
      .split(' ')
      .join('-')
      .toLowerCase();
  }

  // throw Error('Either path or string label should be provided to NavTabs.Tab');
}
