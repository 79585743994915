import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  container: (theme) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(1),
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  }),
  messageContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  message: {
    flex: 1,
  },
  messageText: {
    color: 'inherit',
  },
  messageIcon: (theme) => ({
    marginRight: theme.spacing(1),
    fontSize: fontSize._20,
  }),
  closeButton: {
    color: 'inherit',
  },
  closeIcon: {
    marginLeft: 'auto',
    fontSize: fontSize._20,
  },
  success: (theme) => ({
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.success.contrastText,
    '& .MuiTypography-root': {
      color: theme.palette.success.contrastText,
    },
  }),
  error: (theme) => ({
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    '& .MuiTypography-root': {
      color: theme.palette.error.contrastText,
    },
  }),
  warning: (theme) => ({
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.warning.contrastText,
    '& .MuiTypography-root': {
      color: theme.palette.warning.contrastText,
    },
  }),
  info: (theme) => ({
    backgroundColor: theme.palette.primary.lightest,
    color: theme.palette.text.dark,
  }),
};
