import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Box } from 'material-latest';

import TPLoader from 'components/TP-UI/TPLoader';

import styles from './styles';

const TPAccordion = ({
  variant = 'outlined',
  loading,
  disabled,
  fullWidth,
  onChange,
  defaultExpanded = false,
  elevation,
  className,
  children,
}) => {
  return (
    <Accordion
      variant={variant}
      defaultExpanded={defaultExpanded}
      elevation={elevation}
      sx={[
        styles.root,
        className,
        disabled && styles.disabled,
        loading && styles.loading,
        fullWidth && styles.fullWidth,
        variant === 'elevation' && elevation === 0 && styles.elevation,
        variant === 'filled' && styles.filled,
      ]}
      onChange={onChange}>
      {children}
      {loading ? (
        <Box sx={styles.accordionLoader}>
          <TPLoader />
        </Box>
      ) : null}
    </Accordion>
  );
};

TPAccordion.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'elevation', 'filled']),
  onChange: PropTypes.func,
  elevation: PropTypes.number,
};

export default TPAccordion;
