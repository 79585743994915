import React from 'react';
import PropTypes from 'prop-types';

import TPTabBadge from '../TPTabBadge';

const TPTabLabel = ({ label, badgeContent }) => (
  <>
    {label} {badgeContent ? <TPTabBadge value={badgeContent} /> : null}
  </>
);

TPTabLabel.propTypes = {
  label: PropTypes.node,
  badgeContent: PropTypes.number,
};

export default TPTabLabel;
