import { createAction } from 'redux-actions';

export const getFusionPlusUsersRequest = createAction('GET_FUSION_PLUS_USERS_REQUEST');
export const getFusionPlusUsersSuccess = createAction('GET_FUSION_PLUS_USERS_SUCCESS');
export const getFusionPlusUsersFail = createAction('GET_FUSION_PLUS_USERS_FAIL');
export const cleanupFusionPlusUsers = createAction('CLEANUP_FUSION_PLUS_USERS');

export const downloadFusionPlusUsersRequest = createAction('DOWNLOAD_FUSION_PLUS_USERS_REQUEST');
export const downloadFusionPlusUsersSuccess = createAction('DOWNLOAD_FUSION_PLUS_USERS_SUCCESS');
export const downloadFusionPlusUsersFail = createAction('DOWNLOAD_FUSION_PLUS_USERS_FAIL');

export const deleteFusionPlusUsersRequest = createAction('DELETE_FUSION_PLUS_USERS_REQUEST');
export const deleteFusionPlusUsersSuccess = createAction('DELETE_FUSION_PLUS_USERS_SUCCESS');
export const deleteFusionPlusUsersFail = createAction('DELETE_FUSION_PLUS_USERS_FAIL');

export const chargeFusionPlusUsersRequest = createAction('CHARGE_FUSION_PLUS_USERS_REQUEST');
export const chargeFusionPlusUsersSuccess = createAction('CHARGE_FUSION_PLUS_USERS_SUCCESS');
export const chargeFusionPlusUsersFail = createAction('CHARGE_FUSION_PLUS_USERS_FAIL');
