import { palette } from 'components/CustomThemeProvider/themes/palette';
import { fontSize, fontWeight } from 'components/CustomThemeProviderMui5/themes/font';

export const styles = {
  description: {},
  title: {
    fontWeight: fontWeight.semiBold,
  },
  border: {
    border: `1px solid ${palette.green2}`,
    gridTemplateRows: '50px auto 50px',
    display: 'grid',
  },
  cardContent: {
    gridTemplateRows: 'auto 1fr',
    display: 'grid',
  },
  cardTitle: {
    fontSize: fontSize._20,
    fontWeight: fontWeight.semiBold,
    height: 21,
  },
  button: {},
  imageWrapper: {},
};
