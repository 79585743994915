import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import MyAccounts from '../components/MyAccounts';
import * as selectors from '../selectors';
import * as actionCreators from '../actions';
import * as geolocationActions from 'modules/geolocation/actions';
import * as authSelectors from 'modules/auth/selectors';
import * as myFollowersActions from 'modules/myFollowers/actions';
import { getUserHub } from 'modules/auth/selectors';
import * as apiSelectors from 'modules/api/selectors';
import { Routes } from 'constants/routeConstants';
import queryString from 'query-string';
import { ACCOUNT_SERVERS } from 'constants/index';

class MyAccountsContainer extends PureComponent {
  componentDidMount() {
    const { getCountriesAction, getCountryByIpRequest } = this.props.geolocationActions;
    getCountriesAction();
    getCountryByIpRequest();

    const payload = {};
    const query = queryString.parse(this.props.location.search || '');
    const activeTab = this.props.location.state && this.props.location.state.activeTab;

    if (query.tab === ACCOUNT_SERVERS.DEMO) {
      payload.activeTab = ACCOUNT_SERVERS.DEMO;
    }
    if (activeTab) {
      payload.activeTab = activeTab;
    }
    this.props.actions.accountsPageOpened(payload);
    this.props.actions.getAccountsRequest({ isLive: false });
    this.props.myFollowersActions.getMyFollowersAccountsRequest();
  }

  componentWillUnmount() {
    const {
      actions: { accountsHideDialog },
    } = this.props;
    accountsHideDialog();
  }

  onTabChange = (event, tabValue) => {
    if (!this.props.isAccountsGetPending) {
      this.props.actions.accountsTabChanged(tabValue);
    }
  };

  onLiveFund = (account) => {
    const { push } = this.props;
    push(Routes.PAYMENTS + `?accountId=${account._id}`);
  };

  onUndoArchived = (login) => {
    const {
      actions: { unarchiveAccountModal },
    } = this.props;
    unarchiveAccountModal(login);
  };

  render() {
    const props = {
      onTabChange: this.onTabChange,
      onLiveFund: this.onLiveFund,
      onGoVerification: this.props.actions.proOrRetailOpen,
      activeDialog: this.props.activeDialog,
      demoAccounts: this.demoAccounts,
      isAccountsGetPending: this.props.isAccountsGetPending,
      selectedTab: this.props.activeAccountsTab,
      accounts: this.props.accounts,
      actions: this.props.actions,
      isQuizRequired: this.props.isQuizRequired,
      isQuizPassed: this.props.isQuizPassed,
      userName: this.props.userName,
      rebateAccountsLength: this.props.rebateAccountsLength,
      isLead2: this.props.isLead2,
      isLead3: this.props.isLead3,
      isLead4: this.props.isLead4,
      isFirstVisit: this.props.isFirstVisit,
      wasRejected: this.props.wasRejected,
      userStatus: this.props.userStatus,
      showHidden: this.props.showHidden,
      hideAccountId: this.props.hideAccountId,
      hideAccountIsHidden: this.props.hideAccountIsHidden,
      hideAccountLoading: this.props.hideAccountLoading,
      showLeverage: this.props.showLeverage,
      followerId: this.props.followerId,
      allowCopyAccount: this.props.allowCopyAccount,
      userIsVerified: this.props.userIsVerified,
      userIsPosted: this.props.userIsPosted,
      hub: this.props.hub,
      isTradingDisabled: this.props.isTradingDisabled,
      onUndoArchived: this.onUndoArchived,
      userAllowedAccountCreation: this.props.userAllowedAccountCreation,
    };
    return <MyAccounts {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    activeAccountsTab: selectors.getActiveAccountsTab(state),
    accounts: selectors.getAccountsByActiveTab(state),
    demoAccounts: selectors.getDemoAccounts(state),
    activeDialog: selectors.getActiveDialog(state),
    showHidden: selectors.showHidden(state),
    hideAccountId: selectors.hideAccountId(state),
    hideAccountIsHidden: selectors.hideAccountIsHidden(state),
    isAccountsGetPending: apiSelectors.createPendingSelector(actionCreators.getAccountsRequest)(
      state,
    ),
    isQuizRequired: authSelectors.getIsUserQuizRequired(state),
    isQuizPassed: authSelectors.getUserPassedQuiz(state),
    userName: authSelectors.getUserName(state),
    rebateAccountsLength: selectors.getRebateAccountsLength(state),
    isLead2: authSelectors.getUserIsLead2(state),
    isLead3: authSelectors.getUserIsLead3(state),
    isLead4: authSelectors.getUserIsLead4(state),
    isFirstVisit: authSelectors.getIsFirstVisit(state),
    wasRejected: authSelectors.getWasRejected(state),
    userStatus: authSelectors.getUserStatus(state),
    hideAccountLoading: apiSelectors.createPendingSelector(actionCreators.hideAccountRequest)(
      state,
    ),
    showLeverage: authSelectors.showLeverage(state),
    followerId: authSelectors.getUserFollowerId(state),
    allowCopyAccount: authSelectors.allowCopyAccount(state),
    userIsVerified: authSelectors.getUserIsVerified(state),
    userIsPosted: authSelectors.getUserIsPosted(state),
    isTradingDisabled: authSelectors.getIsTradingDisabled(state),
    hub: getUserHub(state),
    userAllowedAccountCreation: authSelectors.getUserAllowedAccountCreation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    myFollowersActions: bindActionCreators(myFollowersActions, dispatch),
    push: bindActionCreators(push, dispatch),
    geolocationActions: bindActionCreators(geolocationActions, dispatch),
  };
};

MyAccountsContainer.propTypes = {
  push: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  activeAccountsTab: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  activeDialog: PropTypes.string.isRequired,
  isAccountsGetPending: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  isQuizRequired: PropTypes.bool,
  isQuizPassed: PropTypes.bool,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  isFirstVisit: PropTypes.bool.isRequired,
  hub: PropTypes.string.isRequired,
  isTradingDisabled: PropTypes.bool,
  userAllowedAccountCreation: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyAccountsContainer));
