import { combineReducers } from 'redux';

import clientsProfiles from './clientsProfiles';
import clientsAccounts from './clientsAccounts';
import fullClients from './fullClientsProfiles';
import lastRequestId from './lastRequest';

const reducer = combineReducers({
  clientsProfiles,
  clientsAccounts,
  fullClients,
  lastRequestId,
});

export default reducer;
