import { SHOW_SNACKBAR, HIDE_SNACKBAR, UPDATE_OFFSETS } from 'components/TP-UI/TPSnackbar/actions';
import { ALERT_VARIANTS } from 'components/TP-UI/TPAlert/constants';
import { anchorOriginDefault } from 'components/TP-UI/TPSnackbar/config';
import {
  MAX_VISIBLE_SNACKBARS,
  AUTO_HIDE_VARIANT_RESTRICTIONS,
} from 'components/TP-UI/TPSnackbar/context/constants';

const initialState = {
  positionOffsets: {
    'top-left': 0,
    'top-center': 0,
    'top-right': 0,
    'bottom-left': 0,
    'bottom-center': 0,
    'bottom-right': 0,
  },
  queue: [],
  waitingQueue: [],
  defaults: {
    anchorOrigin: anchorOriginDefault,
    variant: ALERT_VARIANTS.INFO,
    message: '',
    open: false,
    autoHide: true,
    autoHideDuration: 3000,
    hideIcon: false,
    minWidth: '400px',
    maxWidth: '400px',
  },
};

const createSnackbarWithDefaults = (state, payload) => {
  const autoHide =
    payload.autoHide ??
    (!AUTO_HIDE_VARIANT_RESTRICTIONS.includes(payload.variant) && state.defaults.autoHide);

  const autoHideDuration = autoHide
    ? payload.autoHideDuration ?? state.defaults.autoHideDuration
    : null;

  return {
    ...state.defaults,
    ...payload,
    key: `tpsnackbar-${Date.now()}`,
    autoHideDuration,
  };
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const newSnackbar = createSnackbarWithDefaults(state, action.payload);
      let newQueue = [...state.queue];
      let newWaitingQueue = [...state.waitingQueue];
      if (newQueue.length < MAX_VISIBLE_SNACKBARS) {
        newQueue.push(newSnackbar);
      } else {
        newWaitingQueue.push(newSnackbar);
      }
      return {
        ...state,
        queue: newQueue,
        waitingQueue: newWaitingQueue,
      };
    }

    case HIDE_SNACKBAR: {
      let filteredQueue = state.queue.filter((snackbar) => snackbar.key !== action.key);
      let newWaitingQueue = [...state.waitingQueue];
      if (newWaitingQueue.length > 0) {
        const nextSnackbar = newWaitingQueue.shift();
        filteredQueue.push(nextSnackbar);
      }
      return {
        ...state,
        queue: filteredQueue,
        waitingQueue: newWaitingQueue,
      };
    }

    case UPDATE_OFFSETS:
      return {
        ...state,
        queue: action.updatedQueue,
        positionOffsets: action.positionOffsets,
      };

    default:
      return state;
  }
};

export { snackbarReducer, initialState };
