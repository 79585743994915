import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '../../Tooltip/components/Tooltip';

export const TMD_QUIZ_MODAL = 'TMD_QUIZ_MODAL';
export const TMD_QUIZ_FORM = 'TMD_QUIZ_FORM';
export const TMD_WARNING_MODAL = 'TMD_WARNING_MODAL';
export const TMD_SUCCESS_MODAL = 'TMD_SUCCESS_MODAL';
export const TMD_LOCKED_MODAL = 'TMD_LOCKED_MODAL';
export const TMD_CHECK_MODAL = 'TMD_CHECK_MODAL';
export const TMD_FAILED_LOGOUT_TIMEOUT = 30000;

export const TMD_TEST_MODAL_TITLE = 'Appropriateness Test';
export const TMD_TEST_MODAL_TITLE_END_ADORNMENT = (
  <Tooltip
    placement="top"
    isLink={false}
    label={<HelpOutlineIcon fontSize={'small'} />}
    value={
      'Trading Forex/CFDs is highly risky. This questionnaire will help determine your suitability to trade these types of financial instruments.'
    }
  />
);
