import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControl, Box } from 'material-latest';

import TPFormLabel from 'components/TP-UI/TPFormLabel';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPRadio from './TPRadio';
import { SIZES } from '../constants';

import styles from './styles';

export const TPRadioGroup = ({
  input,
  legend,
  buttons,
  row,
  size = SIZES.MEDIUM,
  disabled,
  focused,
  error,
  reservedErrorSpace = true,
  ...others
}) => {
  return (
    <FormControl component="fieldset">
      {legend && (
        <TPFormLabel component="legend" disabled={disabled} focuses={focused}>
          {legend}
        </TPFormLabel>
      )}
      <RadioGroup id={others.name} {...input} {...others} row={row}>
        {buttons?.map(({ value, label }) => (
          <TPRadio size={size} key={label} value={value} label={label} disabled={disabled}>
            {label}
          </TPRadio>
        ))}
      </RadioGroup>
      <Box sx={[reservedErrorSpace && styles.errorContainer]}>
        {error && (
          <TPErrorMessage error={error} size={SIZES.SMALL} className={styles[`error_${size}`]} />
        )}
      </Box>
    </FormControl>
  );
};

TPRadioGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  legend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ),
  row: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reservedErrorSpace: PropTypes.bool,
};

const TPReduxRadioGroup = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);

  return (
    <TPRadioGroup {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxRadioGroup;
