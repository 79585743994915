export default {
  root: (theme) => ({
    width: 'fit-content',
    overflow: 'visible',
    padding: theme.spacing(2),
  }),
  clickable: {
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  cardLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
  stretch: {
    flex: 1,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  selected: (theme) => ({
    borderColor: theme.palette.primary.lighter,
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      width: 7,
      backgroundColor: theme.palette.primary.lighter,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
    },
  }),
};
