import { VerificationStatuses } from 'constants/verificationStatuses';

// TODO: Remove after 7 days in production
const ALLOWED_EMAILS_LIST = [
  // Production email
  'dmitry.yegorov@asia-minerals.com',
  'cathrivera01@gmail.com',
  'liquidity@longasia.net',
  'onneewong@gmail.com',
  'tony@thkl.cc',
  'chrisicey0212@gmail.com',
  'admin@centillion.com.hk',
  'reemkem2005@gmail.com',
  'drk9908@hotmail.com',
  'mockingicon@gmail.com',
  'cro.filomena@gmail.com',
  'chiang_soon@yahoo.com.sg',
  'kenykau@gmail.com',
  'lloydushe@yahoo.co.uk',
  'siutin_xd@hotmail.com',
  '1717llx@gmail.com',
  'alex2404yu@hotmail.com',
  'dark.yeo@anantanaka.com',
  'audreydclxvi@gmail.com',
  'xdy4314@hotmail.com',
  'fakevampire8@gmail.com',
  'deric.chen@gmail.com',
  'janetsg719b@gmail.com',
  'bnbmoon88@gmail.com',
  'cheungchifat82@gmail.com',
  'fxtopz@gmail.com',
  'kartherinechan622@yahoo.com',
  'me.kaykei@gmail.com',
  'te-yu@hotmail.com',
  'drgappu2000@yahoo.co.uk',
  'benjaminngsiu@outlook.com',
  'contact@iliosign.com',
  'lhwlhw1987@gmail.com',
  'clientservices@alchemymarkets.com',
  'rickytftse@yahoo.com.hk',
  'lukele75@gmail.com',
  'harveyyy123@gmail.com',
  'vidabuena101@gmail.com',
  'kamus360@gmail.com',
  'chanbacky888@gmail.com',
  'coachdraw@yahoo.com',
  'benjamin.lcf@gmail.com',
  'yipkelvinoo@gmail.com',
  'trading@etoro.com',
  'shiwei@blackwellglobal.com',
  'fecheung@gmail.com',
  'weller136@gmail.com',
  'gerrychng1003@gmail.com',
  'nick@thinkhuge.net',
  'introduce@forexcashbackrebate.com',
  'support@longasiaforex.com',
  'soniang05hk@gmail.com',
  'maxim109@163.com',
  'ib@clearmarketsfx.com',
  'jonachow@yahoo.com',
  'edcchan113@gmail.com',
  'doccb@yahoo.com',
  'test_gp_prod5@awgarstone.com',
  'doweslch@yahoo.com',
  'mklfnn1@gmail.com',
  'leungkwoklum8@gmail.com',
  'lcft1204@gmail.com',
  'turlier.cyrille@gmail.com',
  'susanguff7@gmail.com',
  'syhchan@yahoo.com',
  'pingyee14@gmail.com',
  'pypycourse@gmail.com',
  'nori00000000@gmail.com',
  'phoebe.wm.ho@gmail.com',
  'peterwwilo@yahoo.com.hk',
  'jeromebodden@hotmail.com',
  'isochronous@vaultmail.pw',
  'info@tatchcapital.com',
  'heet007@crypt0b0tz.com',
  'michelle.k.k.ho@hotmail.com',
  'cr196483@gmail.com',
  'catherinerivera654@gmail.com',
  'algopang@gmail.com',
  'access@myprivatefuture.com',
  'yonghan163@hotmail.com',
];
export const isMigrationAllowed = (country, userEmail, status) => {
  if (ALLOWED_EMAILS_LIST.includes(userEmail)) {
    return true;
  } else {
    return !(
      country !== 'Australia' &&
      (status === VerificationStatuses.APPROVED ||
        status === VerificationStatuses.ACTIVE ||
        status === VerificationStatuses.REJECTED ||
        status === VerificationStatuses.FUNDED)
    );
  }
};
