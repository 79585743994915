import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import MenuScrollIndicators from 'modules/common/MenuScrollIndicators';

const PickerMenu = React.forwardRef((props, ref) => {
  const { classes, children } = props;
  return (
    <div className={`${classes.container} ${props.menuContainer ? props.menuContainer : ''}`}>
      <components.Menu {...props}>
        <MenuScrollIndicators autoHeightMax={270}>{children}</MenuScrollIndicators>
      </components.Menu>
    </div>
  );
});

PickerMenu.displayName = 'PickerMenu';

PickerMenu.propTypes = {
  handleTopButtonChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(PickerMenu);
