import financialGuide from 'static/documents/Financial Services Guide ASIC 29-5.pdf';
import pds from 'static/documents/Product_Disclosure_Statement__PDS.pdf';
import terms from 'static/documents/Financial_Product_Service_Terms_-_ASIC.pdf';
import reduceFeesTerms from 'static/Refer a Friend - T&Cs.pdf';
import privacy from 'static/documents/Privacy Policy - ASIC.pdf';
import TMD from 'static/documents/GP_ASIC_TMD_Feburary_2024.pdf';

import termsX from 'static/documents/Financial_Product_Terms_-_VFSC.pdf';
import seyTerms from 'static/documents/Privacy_Policy - VFSC.pdf';
import seyPrivacy from 'static/documents/Privacy_Policy_sey.pdf';
import pdsX from 'static/documents/Product Disclosure Statement - VFSC.pdf';
import privacyX from 'static/documents/Privacy_Policy - VFSC.pdf';

import { GLOBAL_PRIME_X } from '../../../constants';

export const documents = {
  pds,
  terms,
  reduceFeesTerms,
  financialGuide,
  privacy,
  sey_terms: seyTerms,
  sey_privacy: seyPrivacy,
  tmd: TMD,
};

export const documentsX = {
  pds: pdsX,
  terms: termsX,
  privacy: privacyX,
  reduceFeesTerms,
  financialGuide,
  sey_terms: seyTerms,
  sey_privacy: seyPrivacy,
};

const getDocuments = (hub) => {
  if (hub === GLOBAL_PRIME_X) {
    return documentsX;
  }
  return documents;
};

export default getDocuments;
