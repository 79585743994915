import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { SIZES, TRIGGER } from 'components/TP-UI/constants';

const TPCopyButton = ({ text, disabled = false, onClick, size = SIZES.MEDIUM }) => {
  const { t } = useTranslation('common');
  const browserSupport = !!navigator.clipboard;
  const [message, setMessage] = useState(t('labels.copy'));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleNotify = useCallback(() => {
    setMessage(t('labels.copied'));
    setTooltipOpen(true);

    setTimeout(() => {
      setMessage(t('labels.copy'));
      setTooltipOpen(false);
    }, 3000);

    if (onClick) {
      onClick();
    }
  }, [onClick, t]);

  const fallbackCopyTextToClipboard = useCallback((text, onSuccess) => {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      let textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy'); // Security exception may be thrown by some browsers.
        onSuccess();
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }, []);

  const handleClick = useCallback(async () => {
    if (browserSupport) {
      try {
        await navigator.clipboard.writeText(text);
        handleNotify();
      } catch (err) {
        fallbackCopyTextToClipboard(text, handleNotify);
      }
    } else {
      fallbackCopyTextToClipboard(text, handleNotify);
    }
  }, [text, browserSupport, handleNotify, fallbackCopyTextToClipboard]);

  return browserSupport ? (
    <TPTooltip value={tooltipOpen} trigger={TRIGGER.HOVER} content={message}>
      <TPIconButton
        aria-label={t('labels.copy')}
        disabled={disabled}
        onClick={handleClick}
        size={size}>
        <FileCopyOutlinedIcon fontSize={size === SIZES.XSMALL ? 'inherit' : size} />
      </TPIconButton>
    </TPTooltip>
  ) : null;
};

TPCopyButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL, SIZES.XSMALL]),
};

export default TPCopyButton;
