import { fontSize, fontWeight } from '../../CustomThemeProviderMui5/themes/font';

export default {
  container: (theme) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    background: theme.palette.primary.dark,
    fontSize: fontSize._16,
    fontWeight: fontWeight.regular,
    color: theme.palette.text.dark,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  }),
  messageContent: {
    flexGrow: 1,
  },
  message: (theme) => ({
    flex: 1,
    color: 'inherit',
    '& a': {
      fontSize: 'inherit',
      lineHeight: 'normal',
      color: theme.palette.text.dark,
      fontWeight: fontWeight.bold,
      textDecoration: 'underline',
      '&:hover': {
        fontSize: 'inherit',
        lineHeight: 'normal',
        color: theme.palette.primary.contrastText,
        fontWeight: fontWeight.bold,
        textDecoration: 'underline',
      },
    },
  }),
  closeButton: {
    color: 'inherit',
  },
  closeIcon: {
    marginLeft: 'auto',
    fontSize: fontSize._20,
  },
};
