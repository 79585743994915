import api from '..';

export const joinFusionPlusRequest = (data) => {
  return api.post(`/followers/join-plus`, data);
};

export const requestFusionPlusRequest = (data) => {
  return api.post('/followers/request-fusion-plus', data);
};

export const getMyFollowersRequest = () => {
  return api.get('/followers');
};

export const createOwnCopierRequest = (payload) => {
  return api.post('/followers', payload);
};

export const getMyFollowersAccountsRequest = () => {
  return api.get('/followers/account');
};

export const sendReloadTsAccountRequest = ({ accountId, password }) => {
  return api.post(`/followers/account/${accountId}/connect`, { password });
};

export const getMyFollowersMyInvitationsRequest = () => {
  return api.get('/followers/my-invites');
};

export const addCopierByInviteRequest = (payload) => {
  const { inviteFollowerId } = payload;
  delete payload.inviteFollowerId;
  return api.post(`/followers/copier/join/${inviteFollowerId}`, payload);
};

export const joinAddCopierByInviteRequest = addCopierByInviteRequest;

export const addCopierByEmailRequest = (payload) => {
  return api.post(`/followers/copier/email`, payload);
};

export const createFollowerInvitationLinkRequest = (payload) => {
  return api.post(`/followers/copier/link`, payload);
};

export const getMyFollowersJoinIdRequest = ({ id }) => {
  return api.get(`/followers/copier/join/${id}`);
};

export const deleteCopierRequest = (data) => {
  return api.delete(`/followers/copier/join/${data._id}`);
};

export const editCopierRequest = (data) => {
  const { _id } = data;
  delete data._id;
  return api.put(`/followers/copier/join/${_id}`, data);
};

export const getCopierAccountSettingsRequest = ({ mt4login }) => {
  return api.get(`/followers/settings/${mt4login}`);
};

export const updateCopierAccountSettingsRequest = ({ mt4login, data }) => {
  return api.put(`/followers/settings/${mt4login}`, data);
};

export const getFollowerInvitesRequest = () => {
  return api.get(`/followers/invites`);
};

export const deleteInviteRequest = (invite) => {
  return api.delete(`/followers/invites/${invite._id}`);
};

export const editInviteRequest = (invite) => {
  return api.put(`/followers/invites/${invite._id}`, invite);
};

export const myFollowersTurnOffInvitationRequest = (invite) => {
  return api.post(`/followers/invites/${invite._id}/turn-off`);
};

export const editInviteNotificationsRequest = (invite) => {
  return api.post(`/followers/invites/${invite._id}/notifications`, {
    emailNotifications: invite.emailNotifications,
  });
};

export const myFollowersRejectInvitationRequest = (invite) => {
  return api.post(`/followers/invites/${invite}/reject`);
};

export const myFollowersTradesOpenedRequest = () => {
  return api.get(`/followers/trades/opened`);
};

export const myFollowersTradesErrorRequest = () => {
  return api.get(`/followers/trades/error`);
};

export const myFollowersTradesClosedRequest = ({ skip = 0, limit = 50 }) => {
  return api.get(`/followers/trades/closed?skip=${skip}&limit=${limit}`);
};

export const myFollowersGetWaitlistRequest = () => {
  return api.get(`/followers/waitlist`);
};

export const myFollowersJoinWaitlistRequest = (payload) => {
  return api.post(`/followers/waitlist/join`, payload);
};

export const myFollowersJoinWaitlistCopyRequest = (payload) => {
  return api.post(`/followers/waitlist/copy`, payload);
};

export const getCopyTradesReferenceRequest = () => {
  return api.get(`/followers/get-reference`);
};

export const editSelectedCopiersRequest = (data) => {
  return api.put(`/followers/copier/joinAll`, data);
};

export const getMasterAccountsRequest = () => {
  return api.get('/followers/master-accounts');
};

export const deleteMasterAccountRequest = ({ id }) => {
  return api.delete(`/followers/master-accounts/${id}`);
};

export const getUserFollowersMyInvitationsRequest = ({ userId }) => {
  return api.get(`/users/${userId}/fusion-plus/invites`);
};

export const getUserFollowersMyFollowersRequest = ({ userId }) => {
  return api.get(`/users/${userId}/fusion-plus/followers`);
};

export const userFollowersTradesOpenedRequest = ({ userId }) => {
  return api.get(`/users/${userId}/fusion-plus/trades/opened`);
};

export const userFollowersTradesErrorRequest = ({ userId }) => {
  return api.get(`/users/${userId}/fusion-plus/trades/error`);
};

export const userFollowersTradesClosedRequest = ({ userId, skip = 0, limit = 50 }) => {
  return api.get(`/users/${userId}/fusion-plus/trades/closed?skip=${skip}&limit=${limit}`);
};
