import React, { useCallback, useState, memo } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import MuiBottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ReactComponent as DotIcon } from 'static/dot.svg';
import { getTranslation } from 'utils/compositeTranslationHandler';
import styles from './styles.js';

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: '#040D21',
    },
  },
}))(MenuItem);

const BottomNavigationAction = memo(
  ({ history, classes, subItems = [], id, label, url, icon, value, selected }) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isSubMenuExist = subItems.length > 0;

    const handleSubMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
      setOpened(true);
    };

    const handleSubMenuClose = useCallback(() => {
      setAnchorEl(null);
      setOpened(false);
    }, []);

    const handleClick = useCallback(() => {
      if (url) {
        history.push(url);
      }
    }, [history, url]);

    const handleSubMenuClick = useCallback(() => {
      handleSubMenuClose();
      handleClick(url);
    }, [handleClick, url, handleSubMenuClose]);
    const IconComponent = icon;
    return (
      <>
        <MuiBottomNavigationAction
          id={id}
          value={value}
          selected={selected}
          showLabel
          classes={{ selected: classes.selected, label: classes.label }}
          label={label}
          onClick={handleClick}
          icon={
            isSubMenuExist ? (
              <Grid container justify="center" onClick={handleSubMenuOpen}>
                {typeof icon === 'string' ? (
                  <img src={icon} alt={label} className={classes.icon} />
                ) : (
                  <IconComponent sx={{ color: '#DAE5FF', height: 1.5 }} />
                )}
                {opened ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </Grid>
            ) : typeof icon === 'string' ? (
              <img src={icon} alt={label} className={classes.icon} />
            ) : (
              <IconComponent sx={{ color: '#DAE5FF', height: 1.5 }} />
            )
          }
        />
        {isSubMenuExist && (
          <>
            <Backdrop
              open={opened}
              onClick={handleSubMenuClose}
              classes={{ root: opened ? classes.backdropRoot : classes.backdropHidden }}
            />
            <Menu
              id="bottom-nav-sub-menu"
              anchorEl={anchorEl}
              keepMounted
              autoFocus={false}
              open={opened}
              onClose={handleSubMenuClose}
              classes={{ paper: classes.menu }}>
              {subItems.map((item) => (
                <StyledMenuItem key={item.id} onClick={() => handleSubMenuClick(item.url)}>
                  <ListItemIcon>
                    <DotIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.menuItem}>
                        {getTranslation(t, item.label)}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              ))}
            </Menu>
          </>
        )}
      </>
    );
  },
);

BottomNavigationAction.displayName = 'BottomNavigationAction';

export default withRouter(withStyles(styles)(BottomNavigationAction));
