import { common } from '../common';

const OverridesMuiPaper = {
  styleOverrides: {
    outlined: {
      borderColor: common.formControl.root.borderColor,
    },
    rounded: {
      borderRadius: 0,
    },
  },
};

export default OverridesMuiPaper;
