import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import CloseIcon from '@mui/icons-material/Close';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTypography from 'components/TP-UI/TPTypography';
import { SIZES } from 'components/TP-UI/constants';
import { ALERT_ICONS, ALERT_VARIANTS } from './constants';

import styles from './styles';

const TPAlert = ({ variant, onClose, hideIcon = false, minWidth, maxWidth, children }) => {
  const Icon = !hideIcon ? ALERT_ICONS[variant] : null;

  return (
    <Box sx={[styles.container, styles[variant], { minWidth, maxWidth }]}>
      <Box sx={styles.messageContent}>
        {Icon ? <Icon sx={styles.messageIcon} /> : null}
        {typeof children === 'string' ? (
          <TPTypography variant="body2" className={[styles.message, styles.messageText]}>
            {children}
          </TPTypography>
        ) : (
          <Box sx={styles.message}>{children}</Box>
        )}
      </Box>
      {onClose ? (
        <TPIconButton
          size={SIZES.XSMALL}
          aria-label="Close"
          onClick={onClose}
          className={styles.closeButton}>
          <CloseIcon sx={styles.closeIcon} />
        </TPIconButton>
      ) : null}
    </Box>
  );
};

TPAlert.propTypes = {
  variant: PropTypes.oneOf(Object.values(ALERT_VARIANTS)).isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default TPAlert;
