export const IFRAME_URLS = {
  DOWNLOADS:
    'https://webapp.autochartist.com/componentcontainer/metatrader_tab.php?bid=805&locale=en_GB',
  RESEARCH:
    'https://webapp.autochartist.com/componentcontainer/broker.php?broker_id=805&user=email&logintoken=f65bc9f759165943ba0e31adfdbaab56&expire=1774908000&account_type=LIVE&locale=en_GB',
  IDEAS:
    'https://component.autochartist.com/to/?broker_id=805&token=2b28f2e4960e3a934f1c75b4943a3888&expire=1774908000&user=Global-Prime&locale=en_GB&layout=horizontal&account_type=LIVE&trade_now=n#/results',
  MARKET_REPORT:
    'https://reports.autochartist.com/mr/?broker_id=805&token=2b28f2e4960e3a934f1c75b4943a3888&expire=1774908000&user=Global-Prime&locale=en&rid=798&demo=0&css=https://broker-resources.autochartist.com/css/components/805-mr-app.css#/report/latest',
  IMPACT_NEWS:
    'https://app.salesforceiq.com/r?target=655b3097985fe777216c5b12&t=AFwhZf0J54pUrpAIf-jvIkBTNaNTstkUQNUHQ86iv1cP_K9aXkVOqNoi7-Ud80eN38G7vGTaAjaGiwLNoOa8ALv9FoqXBKbQN8dQw-7UUQ53AnazjiWl9_smv9hOlwaLAsRtKuJfasfz&url=https%3A%2F%2Freports.autochartist.com%2Fsignup%2Fhigh-impact-news%3Fbid%3D805%26user%3Demail%26demo%3D0%26locale%3Den%26expire%3D1727647200%26token%3D25932a9cc712bccd9d3a628d063cbf1e%26css%3Dhttps%3A%252F%252Fbroker-resources.autochartist.com%252Fcss%252Fcomponents%252F805-signup-form-high-impact-app.css',
  CORRELATING:
    'https://app.salesforceiq.com/r?target=655b3097985fe777216c5b13&t=AFwhZf0J54pUrpAIf-jvIkBTNaNTstkUQNUHQ86iv1cP_K9aXkVOqNoi7-Ud80eN38G7vGTaAjaGiwLNoOa8ALv9FoqXBKbQN8dQw-7UUQ53AnazjiWl9_smv9hOlwaLAsRtKuJfasfz&url=https%3A%2F%2Freports.autochartist.com%2Fsignup%2Fcorrelating-signals%3Fbid%3D805%26user%3Demail%26demo%3D0%26locale%3Den%26expire%3D1727647200%26token%3D25932a9cc712bccd9d3a628d063cbf1e%26css%3Dhttps%3A%252F%252Fbroker-resources.autochartist.com%252Fcss%252Fcomponents%252F805-signup-form-correlating-app.css',
  PERFORMANCE_STATISTICS: 'https://component.autochartist.com/performancestats-v2/?broker_id=805',
  VOLATILITY_ANALYSIS:
    'https://component.autochartist.com/va/?broker_id=805&token=2b28f2e4960e3a934f1c75b4943a3888&expire=1774908000&user=Global-Prime&locale=en_GB&layout=horizontal&account_type=LIVE&trade_now=n&css=https://broker-resources.autochartist.com/css/components/805-va-app.css#/results',
  NEWS_SENTIMENT:
    'https://news-sentiment.autochartist.com/news-sentiment?theme=light&broker_id=805&account_type=LIVE&user=Global-Prime&expire=1774908000&token=01d781dbc3ae0a679bac5221317830bc&language=en',
  MESSAGING_AND_ALERTS:
    'https://reports.autochartist.com/signup/previews?bid=805&user=email&demo=0&token=f65bc9f759165943ba0e31adfdbaab56&expire=1774908000&css=https:%2F%2Fbroker-resources.autochartist.com%2Fcss%2Fcomponent_container%2F805-mr-app.css&locale=en',
  EDUCATION:
    // 'https://webapp.autochartist.com/componentcontainer/education-brokers-general.php?broker_id=805&user=email&logintoken=f65bc9f759165943ba0e31adfdbaab56&expire=1774908000&account_type=LIVE&locale=en_GB&css=https://broker-resources.autochartist.com/css/component_container/styles-805.css',
    'https://webapp.autochartist.com/componentcontainer/broker.php?broker_id=805&user=email&logintoken=f65bc9f759165943ba0e31adfdbaab56&expire=1774908000&account_type=LIVE&locale=en_GB#tab-8',
};

export const IFRAME_VIDEOS_URLS = {
  ABOUT: 'https://player.vimeo.com/video/310889072?h=211356f1e8',
  INSTALLING: 'https://player.vimeo.com/video/310889018?h=f01a8a451a',
  HOW_TO_USE: 'https://player.vimeo.com/video/310889013?h=c6a573e8db',
};

export const IFRAME_QRCODE_URL =
  'https://app.salesforceiq.com/r?target=66ea791ed6e81d35885da96b&t=AFwhZf1nTPbHB0VAk6xktrYHDzsJY7eow1KU2wcCbQUFWuF5nbwCYrFMen0QMT8ewYrkfpCA_i5igXRqbRUAOlAV2rVuT31JXnbdB5qDszaSl20ILk-HCR3EM2OUryA4RHcPxpidFg5E&url=https%3A%2F%2Fcomponent.autochartist.com%2Fto%2Fresources%2Fmobile%2Fqr%2Fimage%2F%3Fuser%3DGlobal-Prime%26account_type%3DLIVE%26broker_id%3D805%26token%3D2b28f2e4960e3a934f1c75b4943a3888%26expire%3D1774908000%26locale%3Den%26layout%3Dinline%26trade_now%3Dn%23%2Fresults';

export const DOWNLOAD_MOBILE_APP_URLS = {
  IOS: 'https://apps.apple.com/us/app/autochartist/id903348229',
  ANDROID: 'https://play.google.com/store/apps/details?id=com.autochartist.mobile',
};
